import React, { useEffect } from "react";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import Footer from "./Footer";

export default function App() {
  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <div>
      <br />
      <div
        className="primary-text"
        style={{
          margin: "auto",
          maxWidth: "1000px",
          padding: "2% 4%",
          fontSize: "1.7rem",
          fontWeight: "bold",
          color: "#087cc4",
        }}
      >
        Bharat Biofuels: Biomass Briquettes Supply Policies
      </div>
      <MDBContainer
        className="mt-5"
        style={{ maxWidth: "1000px", background: "transparent" }}
      >
        <MDBAccordion alwaysOpen initialActive={1}>
          <MDBAccordionItem
            collapseId={1}
            headerTitle="1. What are the product quality standards that Bharat Biofuels adheres to?"
          >
            At Bharat Biofuels, we are committed to delivering biomass
            briquettes of the highest quality. Our products meet international
            and local standards for safety, environmental impact, and other
            relevant regulations. We implement stringent quality control
            measures to ensure consistent quality in all our biomass briquette
            variants.
          </MDBAccordionItem>
          <MDBAccordionItem
            collapseId={2}
            headerTitle="2. How does Bharat Biofuels ensure environmental responsibility in its operations? "
          >
            Environmental responsibility is a top priority for us. We source
            biomass materials for briquette production through responsible and
            sustainable means, prioritizing suppliers who follow ethical and
            environmentally friendly practices. Additionally, we work diligently
            to reduce our carbon footprint through efficient production
            techniques and logistics, promoting biomass briquettes as a
            renewable energy alternative.
          </MDBAccordionItem>
          <MDBAccordionItem
            collapseId={3}
            headerTitle="3. How does Bharat Biofuels prioritize safety in its operations? "
          >
            Safety is of utmost importance to us. We maintain comprehensive
            safety protocols in all aspects of our production and distribution
            processes. Employees and partners involved in handling biomass
            briquettes are required to use appropriate safety gear and follow
            safe material handling practices.
          </MDBAccordionItem>
          <MDBAccordionItem
            collapseId={4}
            headerTitle="4. What are the product specifications for Bharat Biofuels' biomass briquettes?"
          >
            We provide clear and detailed product specifications for all our
            biomass briquette variants. These specifications ensure consistency
            and quality in our products. Any deviations from these
            specifications must be addressed and approved by the relevant
            authorities before shipping.
          </MDBAccordionItem>
          <MDBAccordionItem
            collapseId={5}
            headerTitle="5. How does Bharat Biofuels handle customer relations? "
          >
            Bharat Biofuels is committed to providing reliable delivery of
            biomass briquettes to customers worldwide. We strive to meet
            agreed-upon delivery schedules and maintain open and transparent
            communication channels with customers to address any concerns
            promptly and efficiently.
          </MDBAccordionItem>
          <MDBAccordionItem
            collapseId={6}
            headerTitle="6. Does Bharat Biofuels follow ethical business practices? "
          >
            Absolutely. We strictly adhere to all relevant laws and regulations
            in the countries we operate in, including anti-corruption,
            antitrust, and international trade laws. We also promote a culture
            of integrity and require employees and partners to avoid any
            conflicts of interest.
          </MDBAccordionItem>
          <MDBAccordionItem
            collapseId={7}
            headerTitle="7. How does Bharat Biofuels protect confidential information? "
          >
            The protection of confidential and proprietary information is a
            priority for us. We implement measures to safeguard sensitive
            information, and employees and partners may be required to sign
            non-disclosure agreements to ensure confidentiality.
          </MDBAccordionItem>
          <MDBAccordionItem
            collapseId={8}
            headerTitle="8. How should employees and partners report policy violations or unethical behavior?"
          >
            Any suspected violations of our policies or unethical behavior
            should be promptly reported to the designated authority within
            Bharat Biofuels. We encourage a transparent reporting process to
            ensure a safe and compliant work environment.
          </MDBAccordionItem>
          <MDBAccordionItem
            collapseId={9}
            headerTitle="9. Can these policies be updated or amended? "
          >
            Yes, Bharat Biofuels reserves the right to update or amend these
            policies as necessary. All changes will be communicated to
            employees, contractors, and partners to ensure everyone stays
            informed and compliant.
          </MDBAccordionItem>
          <MDBAccordionItem
            collapseId={10}
            headerTitle="10. What are the consequences of policy violations?"
          >
            Violations of our policies may result in disciplinary action, which
            could include termination or legal consequences, as appropriate. We
            take all violations seriously to maintain the highest standards of
            excellence and compliance.
          </MDBAccordionItem>
        </MDBAccordion>
      </MDBContainer>
      <Footer />
    </div>
  );
}
