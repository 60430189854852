import React from "react";
import teamPic from "../Assets/Globalization.gif";
import Fade from "react-reveal/Fade";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
const About = () => {
  return (
    <section
      className="about overflow-hidden py-5"
      style={{
        margin: "10% 0",
      }}
    >
      <div className="w-100">
        <div className="row col-md-11 mx-auto ">
          <div className="col-md-6 img">
            <Fade duration={2000} left>
              <img src={`${teamPic}`} alt="" className="img-fluid" />
            </Fade>
          </div>
          <div className="col-md-6 ps-2">
            <Fade duration={2000} right>
              <div className="intro-container">
                <h1 style={{ padding: "21px", width: "fit-content" }}>
                  About Us
                </h1>
              </div>
              {/* <h2 className="headerTitle">
                Integrity Excellence Success,<br></br>
                <span className="headerHighlight"></span>
              </h2> */}
              <br />
              <Fade duration={2000} right>
                <p className="headerContent">
                  Bharat Biofuels is a renowned manufacturer and exporter of
                  biomass briquettes. With years of industry experience and a
                  commitment to environmental stewardship, we have become a
                  trusted name in the market. Our team of experts utilizes
                  advanced technology and sustainable practices to produce
                  briquettes of the highest quality.
                </p>
                <Link
                  to="/vision"
                  className="secondary-button"
                  style={{ width: "12rem", display: "flex", margin: "auto" }}
                >
                  Learn More
                </Link>
              </Fade>
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
