import React, { useEffect } from "react";
import backImg from "../Assets/goals.jpg";
import benefits from "../Assets/Goals.mp4";
import one from "../Assets/1-compressed.jpg";
import two from "../Assets/2-compressed.jpg";
import three from "../Assets/3-compressed.jpg";
import four from "../Assets/4-compressed.jpg";
import five from "../Assets/5-compressed.jpg";
import Footer from "./Footer";
import Sustainability from "./Sustainability";
function Goals() {
  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <div>
      <div
        className="work-section-background"
        style={{
          background: `url(${backImg})`,
          backgroundSize: "contain",
          backgroundRepeat: "repeat-x",
          width: "100%",
        }}
      >
        <div className="work-section-top">
          <h1 className="primary-mainheading">
            <span style={{ fontFamily: "sans-serif" }}>UNSDG</span> Goals
          </h1>
        </div>
      </div>
      <br />
      <div className="bharat-video-content">
        <div className="video-player">
          <video controls loop muted autoPlay playsInline>
            <source src={benefits} type="video/mp4" />
            Sorry your browser not support video
          </video>
        </div>
        <div className="benefits-heading intro-container">
          <h1>
            Bharat Biofuels - Promoting Sustainable Biomass Briquettes for a
            Better Future
          </h1>
        </div>
      </div>
      <br />
      <br />
      <div className="goals-content">
        <h3>Our Commitment to UN Sustainable Development Goals (UNSDGs)</h3>
        <br />
        <div className="goals-para-content">
          At Bharat Biofuels, we take pride in producing high-quality biomass
          briquettes that not only provide an eco-friendly energy solution but
          also contribute to the achievement of the United Nations Sustainable
          Development Goals. Our company is dedicated to promoting
          sustainability and environmental responsibility in every aspect of our
          operations.
        </div>
      </div>
      <div className="goals-image-content">
        <div className="image-content">
          <img src={one} alt="" />
        </div>
        <div
          className="primary-text"
          style={{
            margin: "auto",
            fontSize: "1.25rem",
            textAlign: "left",
            maxWidth: "1000px",
            padding: "3% 2% 4% 2%",
          }}
        >
          1. <b>Affordable and Clean Energy (SDG 7)</b>: We firmly believe that
          access to clean and affordable energy is essential for economic growth
          and sustainable development. Our biomass briquettes offer a renewable
          and clean energy source, reducing the dependence on fossil fuels and
          positively impacting the environment.
        </div>
      </div>
      <div className="goals-image-content-right">
        <div className="image-content">
          <img src={two} alt="" />
        </div>
        <div
          className="primary-text"
          style={{
            margin: "auto",
            fontSize: "1.25rem",
            textAlign: "left",
            maxWidth: "1000px",
            padding: "3% 2% 4% 2%",
          }}
        >
          2. <b>Climate Action (SDG 13)</b>: As part of the global efforts to
          combat climate change, we strive to reduce carbon emissions through
          our biomass briquettes. These briquettes are carbon-neutral, as the
          carbon dioxide released during combustion is offset by the carbon
          absorbed during biomass growth.
        </div>
      </div>
      <div className="goals-image-content">
        <div className="image-content">
          <img src={three} alt="" />
        </div>
        <div
          className="primary-text"
          style={{
            margin: "auto",
            fontSize: "1.25rem",
            textAlign: "left",
            maxWidth: "1000px",
            padding: "3% 2% 4% 2%",
          }}
        >
          3. <b>Responsible Consumption and Production (SDG 12)</b>:Our
          commitment to responsible consumption and production drives us to
          source biomass materials sustainably. By utilizing agricultural and
          forest residues that would otherwise go to waste, we actively
          contribute to efficient waste management and reduce environmental
          pollution.
        </div>
      </div>
      <div className="goals-image-content-right">
        <div className="image-content">
          <img src={four} alt="" />
        </div>
        <div
          className="primary-text"
          style={{
            margin: "auto",
            fontSize: "1.25rem",
            textAlign: "left",
            maxWidth: "1000px",
            padding: "3% 2% 4% 2%",
          }}
        >
          4. <b>Life Below Water and Life on Land (SDGs 14 and 15)</b>: At
          Bharat Biofuels, we are dedicated to preserving the delicate balance
          of our ecosystems. By reducing the demand for traditional firewood,
          our biomass briquettes help combat deforestation, protect
          biodiversity, and support life below water and life on land.
        </div>
      </div>
      <div className="goals-image-content">
        <div className="image-content">
          <img src={five} alt="" />
        </div>
        <div
          className="primary-text"
          style={{
            margin: "auto",
            fontSize: "1.25rem",
            textAlign: "left",
            maxWidth: "1000px",
            padding: "3% 2% 4% 2%",
          }}
        >
          5. <b>Decent Work and Economic Growth (SDG 8)</b>: Our operations
          create employment opportunities, especially in rural areas, fostering
          decent work and economic growth in the communities we serve.Improve
          resource efficiency in consumption and production to enhance
          sustainability.
        </div>
      </div>
      <Sustainability />
      <Footer />
    </div>
  );
}

export default Goals;
