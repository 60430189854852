import React, { useEffect, useRef, useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import Logo from "../Assets/MainLogo.png";
import { HiOutlineBars3 } from "react-icons/hi2";
import { Icon } from "@iconify/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import $ from "jquery";

function Navbar() {
  const [maintoggle, setmainToggle] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [toggle4, setToggle4] = useState(false);
  const [abouttoggle, setAboutToggle] = useState(false);
  const calltoggle = () => {
    setToggle(!toggle);
  };

  function animation() {
    var tabsNewAnim = $("#navbarSupportedContent");
    var activeItemNewAnim = tabsNewAnim.find(".active");
    var activeWidthNewAnimHeight = activeItemNewAnim.innerHeight();
    var activeWidthNewAnimWidth = activeItemNewAnim.innerWidth();
    var itemPosNewAnimTop = activeItemNewAnim.position();
    var itemPosNewAnimLeft = activeItemNewAnim.position();
    $(".hori-selector").css({
      top: itemPosNewAnimTop.top + "px",
      left: itemPosNewAnimLeft.left + "px",
      height: activeWidthNewAnimHeight + "px",
      width: activeWidthNewAnimWidth + "px",
    });
    $("#navbarSupportedContent").on("click", "li", function (e) {
      $("#navbarSupportedContent ul li").removeClass("active");
      $(this).addClass("active");
      var activeWidthNewAnimHeight = $(this).innerHeight();
      var activeWidthNewAnimWidth = $(this).innerWidth();
      var itemPosNewAnimTop = $(this).position();
      var itemPosNewAnimLeft = $(this).position();
      $(".hori-selector").css({
        top: itemPosNewAnimTop.top + "px",
        left: itemPosNewAnimLeft.left + "px",
        height: activeWidthNewAnimHeight + "px",
        width: activeWidthNewAnimWidth + "px",
      });
    });
  }

  const [isSticky, setIsSticky] = useState(false);
  const navbarRef = useRef(null);

  const handleScroll = () => {
    const navbar = navbarRef.current;
    const top = navbar.offsetTop;
    setIsSticky(window.scrollY >= top);
  };

  useEffect(() => {
    animation();
    $(window).on("resize", function () {
      setTimeout(function () {
        animation();
      }, 500);
    });

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    let handler = (e) => {
      const navbar = navbarRef.current;
      if (!navbar.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
  }, []);
  // const [hover, setHover] = useState(false);
  // const toggleHover = () => {
  //   setHover(!hover);
  // };
  // const linkStyle = {
  //   color: hover ? "#ab3af2" : "#ab3af2",
  //   fontWeight: "600",
  // };
  const [open, setOpen] = useState(false);
  return (
    <nav
      className={
        isSticky
          ? "sticky navbar navbar-expand-lg navbar-mainbg"
          : "sticky navbar navbar-expand-lg navbar-mainbg"
      }
      ref={navbarRef}
      style={{
        paddingRight: "5%",
      }}
    >
      <Link className="navbar-brand navbar-logo" to="/">
        <img src={Logo} alt="" />
      </Link>

      <div
        className="collapse navbar-collapse"
        id="navbarSupportedContent"
        style={{
          justifyContent: "end",
          width: "100%",
        }}
      >
        <ul className="navbar-nav ml-auto">
          <div className="hori-selector">
            <div className="left"></div>
            <div className="right"></div>
          </div>

          <li className="nav-item active">
            <Link className="nav-link main-link" to="/">
              Home
            </Link>
          </li>

          <li className="nav-item">
            <div className="about-us">
              <Link className="nav-link main-link" to="/journey">
                Who we are
              </Link>

              <ul className="about-dropdown">
                <li>
                  <Link to="/journey">Our Journey</Link>
                </li>
                <li>
                  <Link to="/directors">Board of Director's</Link>
                </li>
                <li>
                  <Link to="/vision">Vision & Values</Link>
                </li>
                <li>
                  <Link to="/policies">Our Policies</Link>
                </li>
                <li>
                  <Link to="/video-gallery">Video Gallery</Link>
                </li>
              </ul>
            </div>
          </li>

          <li className="nav-item">
            <div className="services">
              <Link
                className="nav-link products-navbar main-link"
                to="/products"
              >
                <span>Products</span>
              </Link>
              <ul className="dropdown">
                <li>
                  <div className="fruits-hover">
                    <Link
                      to="/biomass-briquettes"
                      className="remove_decoration"
                    >
                      Biomass Briquettes
                      <Icon
                        icon="material-symbols:keyboard-double-arrow-right-rounded"
                        className="right-arrow"
                      />
                    </Link>

                    <ul className="fruits-dropdown">
                      <li>
                        <Link to="/biomass-briquettes#scrollbagasse">
                          Bagasse
                        </Link>
                      </li>
                      <li>
                        <Link to="/biomass-briquettes#scrollsoyahusk">
                          Soya Husk
                        </Link>
                      </li>
                      <li>
                        <Link to="/biomass-briquettes#scrollhighgcv">
                          High gcv agriresidue
                        </Link>
                      </li>
                      <li>
                        <Link to="/biomass-briquettes#scrollsawdust">
                          Saw Dust
                        </Link>
                      </li>
                      <li>
                        <Link to="/biomass-briquettes#scrollgroundnut">
                          Groundnut Shell
                        </Link>
                      </li>
                      <li>
                        <Link to="/biomass-briquettes#scrollmustardhusk">
                          Mustard Husk
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div className="spices-hover">
                    <Link to="/biomass-pellets" className="remove_decoration">
                      Biomass Pellets
                      <Icon
                        icon="material-symbols:keyboard-double-arrow-right-rounded"
                        className="right-arrow"
                      />
                    </Link>

                    <ul className="spices-dropdown">
                      <li>
                        <Link to="/biomass-pellets#scrollsixmm">
                          6mm Pellets
                        </Link>
                      </li>
                      <li>
                        <Link to="/biomass-pellets#scrolleightmm">
                          8mm Pellets
                        </Link>
                      </li>
                      <li>
                        <Link to="/biomass-pellets#scrolltenmm">
                          10mm Pellets
                        </Link>
                      </li>
                      <li>
                        <Link to="/biomass-pellets#scrollsixteenmm">
                          16mm Pellets
                        </Link>
                      </li>
                      <li>
                        <Link to="/biomass-pellets#scrolleighteenmm">
                          18mm Pellets
                        </Link>
                      </li>
                      <li>
                        <Link to="/biomass-pellets#scrollthirtymm">
                          30mm Pellets
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <li className="nav-item">
            <Link className="nav-link main-link" to="/benefits">
              Benefits
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link main-link" to="/goal">
              Sustainability
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link main-link" to="/contact">
              Contact
            </Link>
          </li>
        </ul>
      </div>

      <div className="navbar-menu-container">
        <HiOutlineBars3
          onClick={() => {
            setOpen(!open);
          }}
          className="hamburger"
        />
      </div>

      <div>
        <div className={`navbar-drawer ${open ? "active" : "inactive"}`}>
          <div className="sidebar-logo">
            <Link className="navbar-brand navbar-logo" to="/">
              <img src={Logo} alt="" />
            </Link>
            <Icon
              icon="ic:outline-cancel"
              className="main-cross"
              onClick={() => {
                setOpen(!open);
              }}
            />
          </div>
          <ul>
            <div className="item">
              <li
                onClick={() => {
                  setOpen(false);
                }}
              >
                <Link to="/">
                  <Icon icon="material-symbols:home" className="navbar-icon" />
                  Home
                </Link>
              </li>
            </div>
            <li>
              <Link
                to="/journey"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <Icon icon="material-symbols:person" className="navbar-icon" />
                About BB
              </Link>
              <div
                onClick={() => {
                  setAboutToggle(!abouttoggle);
                }}
              >
                {abouttoggle ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </div>
            </li>
            {abouttoggle && (
              <ul>
                <li onClick={() => {
                setOpen(!open);
              }}>
                  <Link to="/journey">
                    <Icon icon="ph:road-horizon-fill" className="navbar-icon" />{" "}
                    Our Journey
                  </Link>
                </li>
                <li onClick={() => {
                setOpen(!open);
              }}>
                  <Link to="/directors">
                    <Icon icon="mdi:person-multiple" className="navbar-icon" />{" "}
                    Board of Director's
                  </Link>
                </li>
                <li onClick={() => {
                setOpen(!open);
              }}>
                  <Link to="/vision">
                    <Icon icon="bx:target-lock" className="navbar-icon" />{" "}
                    Vision & Values
                  </Link>
                </li>
                <li onClick={() => {
                setOpen(!open);
              }}>
                  <Link to="/policies">
                    <Icon icon="basil:document-solid" className="navbar-icon" />{" "}
                    Our Policies
                  </Link>
                </li>
                <li onClick={() => {
                setOpen(!open);
              }}>
                  <Link to="/video-gallery">
                    <Icon icon="ph:video-fill" className="navbar-icon" /> Video
                    Gallery
                  </Link>
                </li>
              </ul>
            )}
            <li>
              <Link
                to="/products"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <Icon icon="mdi:bag-personal-outline" className="navbar-icon" />
                Products
              </Link>
              <div
                onClick={() => {
                  setmainToggle(!maintoggle);
                }}
              >
                {maintoggle ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </div>
            </li>
            {maintoggle && (
              <ul>
                <li>
                  <Link
                    to="/biomass-briquettes"
                    onClick={() => {
                      setOpen(false);
                    }}
                    className="products-size"
                  >
                    <Icon icon="game-icons:wood-pile" className="navbar-icon" />
                    Biomass Briquettes
                  </Link>
                  <div onClick={calltoggle}>
                    {toggle ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </div>
                </li>
                {toggle && (
                  <ul>
                    <li>
                      <Link to="/biomass-briquettes#scrollbagasse">
                        <Icon icon="solar:bag-3-bold" className="navbar-icon" />
                        Bagasse
                      </Link>
                    </li>
                    <li>
                      <Link to="/biomass-briquettes#scrollsoyahusk">
                        <Icon
                          icon="game-icons:soda-can"
                          className="navbar-icon"
                        />
                        Soya Husk{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="/biomass-briquettes#scrollhighgcv">
                        <Icon
                          icon="game-icons:triple-shells"
                          className="navbar-icon"
                        />
                        Gcv Agriresidue
                      </Link>
                    </li>
                    <li>
                      <Link to="/biomass-briquettes#scrollsawdust">
                        <Icon
                          icon="material-symbols:water"
                          className="navbar-icon"
                        />
                        Saw Dust
                      </Link>
                    </li>
                    <li>
                      <Link to="/biomass-briquettes#scrollgroundnut">
                        <Icon
                          icon="game-icons:powder"
                          className="navbar-icon"
                        />
                        Groundnut Shell
                      </Link>
                    </li>
                    <li>
                      <Link to="/biomass-briquettes#scrollmustardhusk">
                        <Icon
                          icon="game-icons:powder-bag"
                          className="navbar-icon"
                        />
                        Mustard Husk
                      </Link>
                    </li>
                  </ul>
                )}
                <li>
                  <Link
                    to="/biomass-pellets"
                    onClick={() => {
                      setOpen(false);
                    }}
                    className="products-size"
                  >
                    <Icon
                      icon="fluent-mdl2:empty-recycle-bin"
                      className="navbar-icon"
                    />
                    Biomass Pellets
                  </Link>
                  <div
                    onClick={() => {
                      setToggle4(!toggle4);
                    }}
                  >
                    {toggle4 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </div>
                </li>
                {toggle4 && (
                  <ul>
                    <li>
                      <Link to="/biomass-pellets#scrollsixmm">
                        <Icon
                          icon="material-symbols:background-grid-small"
                          className="navbar-icon"
                        />
                        6mm Pellets
                      </Link>
                    </li>

                    <li>
                      <Link to="/biomass-pellets#scrolleightmm">
                        <Icon
                          icon="icon-park-outline:heavy-metal"
                          className="navbar-icon"
                        />
                        8mm Pellets
                      </Link>
                    </li>
                    <li>
                      <Link to="/biomass-pellets#scrolltenmm">
                        <Icon
                          icon="game-icons:metal-bar"
                          className="navbar-icon"
                        />
                        10mm Pellets
                      </Link>
                    </li>
                    <li>
                      <Link to="/biomass-pellets#scrollsixteenmm">
                        <Icon
                          icon="material-symbols:calendar-view-week"
                          className="navbar-icon"
                        />
                        16mm Pellets
                      </Link>
                    </li>
                    <li>
                      <Link to="/biomass-pellets#scrolleighteenmm">
                        <Icon
                          icon="material-symbols:drag-handle"
                          className="navbar-icon"
                        />
                        18mm Pellets
                      </Link>
                    </li>
                    <li>
                      <Link to="/biomass-pellets#scrollthirtymm">
                        <Icon icon="tabler:wood" className="navbar-icon" />
                        30mm Pellets
                      </Link>
                    </li>
                  </ul>
                )}
                {/* <li>
                  <Link
                    to="/api"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <Icon
                      icon="game-icons:medicine-pills"
                      className="navbar-icon"
                    />
                    API
                  </Link>
                  <div
                    onClick={() => {
                      setToggle8(!toggle8);
                    }}
                  >
                    {toggle8 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </div>
                </li>
                {toggle8 && (
                  <ul>
                    <li>
                      <Link to="/iodine">
                        <Icon
                          icon="solar:document-medicine-bold"
                          className="navbar-icon"
                        />
                        Iodine
                      </Link>
                    </li>
                    <li>
                      <Link to="/piperazine">
                        <Icon
                          icon="icon-park-solid:medicine-bottle-one"
                          className="navbar-icon"
                        />
                        Piperazine
                      </Link>
                    </li>
                    <li>
                      <Link to="/citrate">
                        <Icon
                          icon="ri:medicine-bottle-fill"
                          className="navbar-icon"
                        />
                        Citrate
                      </Link>
                    </li>
                    <li>
                      <Link to="/other-api">
                        <Icon
                          icon="icon-park-outline:other"
                          className="navbar-icon"
                        />
                        Other APIs
                      </Link>
                    </li>
                    <li>
                      <Link to="/spiceole">
                        <Icon
                          icon="icon-park-outline:medicine-bottle"
                          className="navbar-icon"
                        />
                        Spice Oleoresin
                      </Link>
                    </li>
                  </ul>
                )} */}
              </ul>
            )}

            <li
              onClick={() => {
                setOpen(false);
              }}
            >
              <Link to="/benefits">
                <Icon
                  icon="fluent-mdl2:nonprofit-logo-32"
                  className="navbar-icon"
                />
                Benefits
              </Link>
            </li>
            <li
              onClick={() => {
                setOpen(false);
              }}
            >
              <Link to="/goal">
                <Icon icon="octicon:goal-16" className="navbar-icon" />
                Sustainability
              </Link>
            </li>
            <li
              onClick={() => {
                setOpen(false);
              }}
            >
              <Link to="/contact">
                <Icon
                  icon="material-symbols:phone-enabled-sharp"
                  hFlip={true}
                  className="navbar-icon"
                />
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
export default Navbar;
