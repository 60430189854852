import React from "react";
import GalleryB from "./GalleryB";
import GalleryP from "./GalleryP";
function Products() {
  return (
    <div>
      <GalleryB heading="Biomass Briquettes" />
      <br /> <GalleryP heading="Biomass Pellets" />
      <br />
    </div>
  );
}

export default Products;
