import React, { useEffect } from "react";
import vision from "../../Assets/vision.jpg";
import vision_bg from "../../Assets/vision_bg.png";
import values from "../../Assets/vision-1.png";
import Footer from "../Footer";
function Vision() {
  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <div>
      <div
        className="vision-background"
        style={{ backgroundImage: `url(${vision})` }}
      ></div>
      <div className="vision-background-lower">
        <h3>
          VISION AND VALUES &nbsp;&nbsp;|&nbsp;&nbsp;DISCOVER WHAT FUELS US
        </h3>
      </div>
      <br />
      <br />
      <div
        className="primary-text"
        style={{
          margin: "auto",
          maxWidth: "1000px",
          padding: "4% 4%",
          fontSize: "1.7rem",
          fontWeight: "bold",
          color: "#087cc4",
        }}
      >
        VISION
      </div>
      <div
        className="vision-content-background"
        style={{ backgroundImage: `url(${vision_bg})` }}
      >
        <div className="vision-content">
          <div className="vision-1">
            <img
              src="https://www.bharatpetroleum.in/images/site-icons/vision01.png"
              alt=""
            />
            <p>
              We are the most abmire global energy company leverging talent and
              technology
            </p>
          </div>
          <div className="vision-1">
            <img
              src="https://www.bharatpetroleum.in/images/site-icons/vision04.png"
              alt=""
              style={{ scale: "1.2" }}
            />
            <p>
              We are role models for implementing efficient Health Safety,
              Security and Environment initiatives.
            </p>
          </div>
          <div className="vision-1">
            <img
              src="https://i.ibb.co/HFcQRbG/Screenshot-2023-07-28-120512.png"
              alt=""
            />
            <p>
              We are a great organisation to work for as we ensure both personal
              and professional growth equally.
            </p>
          </div>
        </div>
        <div className="vision-content">
          <div className="vision-1">
            <img
              src="https://www.bharatpetroleum.in/images/site-icons/vision07.png"
              alt=""
              style={{ scale: "1.5" }}
            />
            <p>
              We take social responsibility seriously as a model corporate
              entity.
            </p>
          </div>
          <div className="vision-1">
            <img
              src="https://www.bharatpetroleum.in/images/site-icons/vision06.png"
              alt=""
              style={{ scale: "1.2" }}
            />
            <p>
              We are an organisation that constantly learns, improves and
              innovates.
            </p>
          </div>
          <div className="vision-1">
            <img
              alt=""
              src="https://i.ibb.co/233Lmw4/Screenshot-2023-07-28-121213.png"
            />
            <p>We promote reduce, reuse, recycle for sustainable future.</p>
          </div>
        </div>
      </div>
      <div
        className="primary-text"
        style={{
          margin: "auto",
          maxWidth: "1000px",
          padding: "4% 4%",
          fontSize: "1.7rem",
          fontWeight: "bold",
          color: "#087cc4",
        }}
      >
        VALUES
      </div>
      <div className="values-img" style={{ padding: "0 5%" }}>
        <img src={values} alt="" />
      </div>
      <Footer />
    </div>
  );
}

export default Vision;
