import React, { useState } from "react";
//import ReadMore from "./ReadMore";
import "./ReadMore.css";
function Sustainability() {
  const [isActive, setIsActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
    setIsActive(!isActive);
  };
  return (
    <div>
      <div
        className="primary-text"
        style={{
          margin: "auto",
          maxWidth: "1000px",
          padding: "4% 4%",
          fontSize: "1.7rem",
          fontWeight: "bold",
          color: "#087cc4",
        }}
      >
        Sustainable Development Goals – For Harmonious Growth
      </div>
      <div className="work-section-sustainability">
        <p>
          The path that leads to a congenial blending of growth with societal
          and environmental goals is the path of sustainability. Anchored firmly
          to the bedrock of sustainability, the corporate philosophy of Bharat
          Biofuels is aligned with the seventeen U.N. Sustainable Development
          Goals (UN SDGs).
        </p>
      </div>
      <div
        className={isActive ? "overlay active" : "overlay"}
        onClick={(index) => {
          setActiveIndex(activeIndex === index ? null : index);
          setIsActive(!isActive);
        }}
      ></div>
      <div className="s-content-img">
        <div className="item">
          <div className="s-box">
            <img
              src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-01.png"
              alt=""
              onClick={() => {
                handleAccordionClick(1);
              }}
            />
          </div>
        </div>
        {activeIndex === 1 && (
          <div className={isActive ? "popup active" : "popup"}>
            <div className="active-content">
              <img
                src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-01.png"
                alt=""
              />
              <h3>Goal-1 &nbsp; No Poverty</h3>
            </div>
            <p>
              Empowering communities, fueling progress. Together, we fight
              poverty through sustainable biomass briquettes that meets the
              needs of the present, without compromising the ability of future
              generations.
            </p>
            <button
              className="read-button"
              onClick={() => {
                handleAccordionClick(1);
              }}
            >
              Close
            </button>
          </div>
        )}
        <div className="item">
          <div className="s-box">
            <img
              src="	https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-02.png"
              alt=""
              onClick={() => {
                handleAccordionClick(2);
              }}
            />
          </div>
        </div>
        {activeIndex === 2 && (
          <div className={isActive ? "popup active" : "popup"}>
            <div className="active-content">
              <img
                src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-02.png"
                alt=""
              />
              <h3>Goal-2 &nbsp; Zero Hunger</h3>
            </div>
            <p>
              Fighting Hunger with Sustainable Biomass Briquettes. Join our
              mission to achieve Zero Hunger through eco-friendly fuel solutions
              to stop world hunger. The goals of the Zero Hunger initiative are
              to end hunger.
            </p>
            <button
              className="read-button"
              onClick={() => {
                handleAccordionClick(2);
              }}
            >
              Close
            </button>
          </div>
        )}
        <div className="item">
          <div className="s-box">
            <img
              src="	https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-03.png"
              alt=""
              onClick={() => {
                handleAccordionClick(3);
              }}
            />
          </div>
        </div>
        {activeIndex === 3 && (
          <div className={isActive ? "popup active" : "popup"}>
            <div className="active-content">
              <img
                src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-03.png"
                alt=""
              />
              <h3>Goal-3 &nbsp; Good Health and Well-being</h3>
            </div>
            <p>
              Contributing to good health by producing sustainable biomass
              briquettes, promoting cleaner energy and reducing air pollution,
              thus supporting better health and well-being for all in every
              generation.
            </p>
            <button
              className="read-button"
              onClick={() => {
                handleAccordionClick(3);
              }}
            >
              Close
            </button>
          </div>
        )}
        <div className="item">
          <div className="s-box">
            <img
              src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-04.png"
              alt=""
              onClick={() => {
                handleAccordionClick(4);
              }}
            />
          </div>
        </div>
        {activeIndex === 4 && (
          <div className={isActive ? "popup active" : "popup"}>
            <div className="active-content">
              <img
                src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-04.png"
                alt=""
              />
              <h3>Goal-4 &nbsp; Quality Education</h3>
            </div>
            <p>
              We empower communities through sustainable biomass briquettes
              while promoting Quality Education, fostering awareness and
              knowledge on renewable energy solutions, promote lifelong learning
              opportunities for all.
            </p>
            <button
              className="read-button"
              onClick={() => {
                handleAccordionClick(4);
              }}
            >
              Close
            </button>
          </div>
        )}
        <div className="item">
          <div className="s-box">
            <img
              src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-05.png"
              alt=""
              onClick={() => {
                handleAccordionClick(5);
              }}
            />
          </div>
        </div>
        {activeIndex === 5 && (
          <div className={isActive ? "popup active" : "popup"}>
            <div className="active-content">
              <img
                src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-05.png"
                alt=""
              />
              <h3>Goal-5 &nbsp; Gender Equality</h3>
            </div>
            <p>
              We proudly promotes Gender Equality, fostering an inclusive
              workforce and empowering women at all levels of the organization.
              A longstanding challenge due to deeply ingrained patriarchal
              attitudes.
            </p>
            <button
              className="read-button"
              onClick={() => {
                handleAccordionClick(5);
              }}
            >
              Close
            </button>
          </div>
        )}
        <div className="item">
          <div className="s-box">
            <img
              src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-06.png"
              alt=""
              onClick={() => {
                handleAccordionClick(6);
              }}
            />
          </div>
        </div>
        {activeIndex === 6 && (
          <div className={isActive ? "popup active" : "popup"}>
            <div className="active-content">
              <img
                src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-06.png"
                alt=""
              />
              <h3>Goal-6 &nbsp; Clean Water and Sanitation</h3>
            </div>
            <p>
              We supports in promoting clean water and sanitation through
              eco-friendly biomass briquettes production and export. Together,
              let's build a sustainable future and helps in developing
              harmonious future.
            </p>
            <button
              className="read-button"
              onClick={() => {
                handleAccordionClick(6);
              }}
            >
              Close
            </button>
          </div>
        )}
        <div className="item">
          <div className="s-box">
            <img
              src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-07.png"
              alt=""
              onClick={() => {
                handleAccordionClick(7);
              }}
            />
          </div>
        </div>
        {activeIndex === 7 && (
          <div className={isActive ? "popup active" : "popup"}>
            <div className="active-content">
              <img
                src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-07.png"
                alt=""
              />
              <h3>Goal-7 &nbsp; Affordable and Clean Energy</h3>
            </div>
            <p>
              We plays a crucial role through manufacturing and export of
              biomass briquettes. By providing a renewable energy source, they
              contribute to the achievement of ensuring access and clean energy
              for all.
            </p>
            <button
              className="read-button"
              onClick={() => {
                handleAccordionClick(7);
              }}
            >
              Close
            </button>
          </div>
        )}
        <div className="item">
          <div className="s-box">
            <img
              src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-08.png"
              alt=""
              onClick={() => {
                handleAccordionClick(8);
              }}
            />
          </div>
        </div>
        {activeIndex === 8 && (
          <div className={isActive ? "popup active" : "popup"}>
            <div className="active-content">
              <img
                src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-08.png"
                alt=""
              />
              <h3>Goal-8 &nbsp; Decent Work and Economic Growth</h3>
            </div>
            <p>
              On manufacturing and exporting biomass briquettes helps in
              sustainable practices create job opportunities and contribute to
              economic development, aligning with the UN Sustainable Development
              Goal.
            </p>
            <button
              className="read-button"
              onClick={() => {
                handleAccordionClick(8);
              }}
            >
              Close
            </button>
          </div>
        )}
        <div className="item">
          <div className="s-box">
            <img
              src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-09.png"
              alt=""
              onClick={() => {
                handleAccordionClick(9);
              }}
            />
          </div>
        </div>
        {activeIndex === 9 && (
          <div className={isActive ? "popup active" : "popup"}>
            <div className="active-content">
              <img
                src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-09.png"
                alt=""
              />
              <h3>Goal-9 &nbsp; Industry and Innovation</h3>
            </div>
            <p>
              Sustainable approach contributes to achieving the UN Sustainable
              Development Goal of promoting innovation and building resilient
              infrastructure with manufacturing and exporting biomass
              briquettes.
            </p>
            <button
              className="read-button"
              onClick={() => {
                handleAccordionClick(9);
              }}
            >
              Close
            </button>
          </div>
        )}
        <div className="item">
          <div className="s-box">
            <img
              src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-10.png"
              alt=""
              onClick={() => {
                handleAccordionClick(10);
              }}
            />
          </div>
        </div>
        {activeIndex === 10 && (
          <div className={isActive ? "popup active" : "popup"}>
            <div className="active-content">
              <img
                src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-10.png"
                alt=""
                onClick={() => {
                  handleAccordionClick(10);
                }}
              />
              <h3>Goal-10 &nbsp; Reduced Inequalities</h3>
            </div>
            <p>
              Empowering local communities through their biomass briquette
              manufacturing and export operations. They aim to create a positive
              impact on society by promoting fair wages by providing job
              opportunities.
            </p>
            <button
              className="read-button"
              onClick={() => {
                handleAccordionClick(10);
              }}
            >
              Close
            </button>
          </div>
        )}
        <div className="item">
          <div className="s-box">
            <img
              src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-11.png"
              alt=""
              onClick={() => {
                handleAccordionClick(11);
              }}
            />
          </div>
        </div>
        {activeIndex === 11 && (
          <div className={isActive ? "popup active" : "popup"}>
            <div className="active-content">
              <img
                src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-11.png"
                alt=""
                onClick={() => {
                  handleAccordionClick(11);
                }}
              />
              <h3>Goal-11 &nbsp; Sustainable Cities and Communities</h3>
            </div>
            <p>
              We manufacture and export biomass briquettes which helps in
              eco-friendly biofuels contribute to reducing greenhouse gas
              emissions, and fostering sustainable energy solutions for urban
              areas.
            </p>
            <button
              className="read-button"
              onClick={() => {
                handleAccordionClick(11);
              }}
            >
              Close
            </button>
          </div>
        )}
        <div className="item">
          <div className="s-box">
            <img
              src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-12.png"
              alt=""
              onClick={() => {
                handleAccordionClick(12);
              }}
            />
          </div>
        </div>
        {activeIndex === 12 && (
          <div className={isActive ? "popup active" : "popup"}>
            <div className="active-content">
              <img
                src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-12.png"
                alt=""
              />
              <h3>Goal-12 &nbsp; Responsible Consumption and Production</h3>
            </div>
            <p>
              Providing a sustainable alternative to traditional fuels, ensuring
              responsible consumption and production for a greener and more
              sustainable future by manufacturing and exporting biomass
              briquettes.
            </p>
            <button
              className="read-button"
              onClick={() => {
                handleAccordionClick(12);
              }}
            >
              Close
            </button>
          </div>
        )}
        <div className="item">
          <div className="s-box">
            <img
              src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-13.png"
              alt=""
              onClick={() => {
                handleAccordionClick(13);
              }}
            />
          </div>
        </div>
        {activeIndex === 13 && (
          <div className={isActive ? "popup active" : "popup"}>
            <div className="active-content">
              <img
                src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-13.png"
                alt=""
              />
              <h3>Goal-13 &nbsp; Climate Action</h3>
            </div>
            <p>
              Sustainable energy solution aligns with the UN Sustainable
              Development Goal of climate action, contributing to a cleaner and
              greener future for all as we manufacture and export biomass
              briquettes.
            </p>
            <button
              className="read-button"
              onClick={() => {
                handleAccordionClick(13);
              }}
            >
              Close
            </button>
          </div>
        )}
        <div className="item">
          <div className="s-box">
            <img
              src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-14.png"
              alt=""
              onClick={() => {
                handleAccordionClick(14);
              }}
            />
          </div>
        </div>
        {activeIndex === 14 && (
          <div className={isActive ? "popup active" : "popup"}>
            <div className="active-content">
              <img
                src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-14.png"
                alt=""
              />
              <h3>Goal-14 &nbsp; Life Below Water</h3>
            </div>
            <p>
              Sustainable Development Goal of 'Life Below Water' by reducing
              environmental impact. Through its eco-friendly approach, the
              company contributes to preserving marine life and protecting our
              oceans.
            </p>
            <button
              className="read-button"
              onClick={() => {
                handleAccordionClick(14);
              }}
            >
              Close
            </button>
          </div>
        )}
        <div className="item">
          <div className="s-box">
            <img
              src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-15.png"
              alt=""
              onClick={() => {
                handleAccordionClick(15);
              }}
            />
          </div>
        </div>
        {activeIndex === 15 && (
          <div className={isActive ? "popup active" : "popup"}>
            <div className="active-content">
              <img
                src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-15.png"
                alt=""
              />
              <h3>Goal-15 &nbsp; Life on Land</h3>
            </div>
            <p>
              UN Sustainable Development Goal manufacturing and exporting
              biomass briquettes. Their eco-friendly approach supports land
              conservation and biodiversity, contributing to a healthier planet
              for all.
            </p>
            <button
              className="read-button"
              onClick={() => {
                handleAccordionClick(15);
              }}
            >
              Close
            </button>
          </div>
        )}
        <div className="item">
          <div className="s-box">
            <img
              src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-16.png"
              alt=""
              onClick={() => {
                handleAccordionClick(16);
              }}
            />
          </div>
        </div>
        {activeIndex === 16 && (
          <div className={isActive ? "popup active" : "popup"}>
            <div className="active-content">
              <img
                src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-16.png"
                alt=""
              />
              <h3>Goal-16&nbsp; Piece, Jusitice and Strong Institutions</h3>
            </div>
            <p>
              Sustainable Development Goal of peace, justice, and strong
              institutions. They contribute to a more peaceful and equitable
              world while fostering responsible practices for the betterment of
              society.
            </p>
            <button
              className="read-button"
              onClick={() => {
                handleAccordionClick(16);
              }}
            >
              Close
            </button>
          </div>
        )}
        <div className="item">
          <div className="s-box">
            <img
              src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-17.png"
              alt=""
              onClick={() => {
                handleAccordionClick(17);
              }}
            />
          </div>
        </div>
        {activeIndex === 17 && (
          <div className={isActive ? "popup active" : "popup"}>
            <div className="active-content">
              <img
                src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/E-WEB-Goal-17.png"
                alt=""
              />
              <h3>Goal-17&nbsp; Partnership for goals</h3>
            </div>
            <p>
              Through our manufacturing and export of biomass briquettes, we aim
              to collaborate with various stakeholders to foster economic
              growth, promote clean energy, and contribute to poverty
              eradication.
            </p>
            <button
              className="read-button"
              onClick={() => {
                handleAccordionClick(17);
              }}
            >
              Close
            </button>
          </div>
        )}
        <div className="item">
          <div className="s-box">
            <img
              src="https://www.bharatpetroleum.in/images/harmonious-growth/sdg-Icons/Global-Goals-Icon.png"
              alt=""
            />
          </div>
        </div>
      </div>
      {/* <ReadMore /> */}
    </div>
  );
}

export default Sustainability;
