import React, { useEffect } from "react";
import backImg from "../Assets/unnamed.jpg";
import { Icon } from "@iconify/react";
import benefits from "../Assets/benefits_.mp4";
import Footer from "./Footer";
function Benefits() {
  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <div>
      <div className="work-section-wrapper">
        <div
          className="work-section-background"
          style={{
            background: `url(${backImg})`,
            backgroundSize: "100% 100%",
            width: "100%",
          }}
        >
          <div className="work-section-top">
            <h1 className="primary-mainheading">
              Benefits of Biomass Briquettes
            </h1>
          </div>
        </div>
      </div>
      <br />
      <div className="bharat-video-content">
        <div className="video-player">
          <video controls loop muted autoPlay playsInline>
            <source src={benefits} type="video/mp4" />
            Sorry your browser not support video
          </video>
        </div>
        <div className="benefits-heading intro-container">
          <h1>Advancing sustainably, nature's legacy protected.</h1>
        </div>
      </div>
      <div
        className="primary-text"
        style={{
          margin: "auto",
          fontSize: "1.25rem",
          textAlign: "left",
          maxWidth: "1000px",
          padding: "3% 2% 4% 2%",
        }}
      >
        1. <b>Renewable and Sustainable Energy</b>: Biomass briquettes are made
        from organic waste materials, which are renewable and readily available.
        By using biomass briquettes, we can reduce dependence on finite fossil
        fuels and promote a sustainable energy source.
        <br />
        <br />
        2. <b>Carbon Neutrality</b>: Biomass briquettes have a minimal carbon
        footprint. The carbon dioxide released during combustion is roughly
        equal to the amount absorbed during the growth of the biomass source,
        making biomass briquettes a carbon-neutral fuel.
        <br />
        <br />
        3. <b>Efficient Energy Conversion</b>: Biomass briquettes have a high
        calorific value and provide efficient combustion. They produce a high
        heat output, making them suitable for various applications such as
        cooking, heating, and electricity generation.
        <br />
        <br />
        4. <b>Waste Management Solution</b>: Biomass briquettes help in
        effective waste management. They utilize agricultural and forestry
        residues, which would otherwise be burned or left to decompose,
        releasing greenhouse gases. By converting this waste into briquettes, it
        reduces pollution and promotes cleaner surroundings.
        <br />
        <br />
        5. <b>Versatile Applications</b>: Biomass briquettes can be used in a
        wide range of sectors, including residential, industrial, and
        commercial. They can replace traditional fuels like coal, wood, and
        charcoal in cooking stoves, boilers, and furnaces. Biomass briquettes
        can also be used as feedstock in some industrial processes and thermal
        power plants.
        <br></br>
        <br></br>
      </div>

      <div className="benefits">
        <h3>How Bharat Biofuels is solving the problem?</h3>
      </div>
      <div style={{ display: "table", margin: "auto" }}>
        <div className="more-container">
          <div className="more-card">
            <div className="more-img">
              <Icon icon="mdi:plant-outline" color="green" className="img" />
            </div>
            <div className="more-content">
              <h4>Sustainable Production</h4>
              <p>
                Bharat Biofuels focuses on sourcing biomass feedstock from
                renewable and sustainable sources. We work closely with local
                farmers, industries, and forestry departments to collect
                agricultural and forestry residues, thereby promoting
                sustainable practices.
              </p>
            </div>
          </div>
          <div className="more-card">
            <div className="more-img">
              <Icon
                icon="material-symbols:biotech"
                color="#8c8c8c"
                className="img"
              />
            </div>
            <div className="more-content">
              <h4>High-Tech Manufacturing</h4>
              <p>
                Our company utilizes advanced manufacturing technology and
                equipment to convert biomass feedstock into high-quality
                briquettes efficiently. This ensures consistent product quality
                and maximizes the energy content of the briquettes.
              </p>
            </div>
          </div>
          <div className="more-card">
            <div className="more-img">
              <Icon
                icon="ant-design:setting-filled"
                color="#5c5c52"
                className="img"
              />
            </div>
            <div className="more-content">
              <h4>Customized Briquette Solutions</h4>
              <p>
                Bharat Biofuels offers customized briquette solutions based on
                specific customer requirements. We work closely with our clients
                to understand their needs and provides which is suitable to them
              </p>
            </div>
          </div>
          <div className="more-card">
            <div className="more-img">
              <Icon icon="fa-solid:award" color="#fcd40b" className="img" />
            </div>
            <div className="more-content">
              <h4>Quality Assurance</h4>
              <p>
                We maintain stringent quality control measures throughout the
                production process to ensure that our biomass briquettes meet
                the highest standards. This includes testing the calorific
                value, moisture content etc.
              </p>
            </div>
          </div>
          <div className="more-card">
            <div className="more-img">
              <Icon icon="gridicons:globe" color="brown" className="img" />
            </div>
            <div className="more-content">
              <h4>Environmental Impact</h4>
              <p>
                Bharat Biofuels is committed to reducing environmental impact.
                Using biomass residues prevents their burning or decomposition,
                thereby reducing greenhouse gas emissions and air pollution.
              </p>
            </div>
          </div>
          <div className="more-card">
            <div className="more-img">
              <Icon
                icon="fluent-mdl2:education"
                color="black"
                className="img"
              />
            </div>
            <div className="more-content">
              <h4>Awareness and Education</h4>
              <p>
                We actively promote the benefits of biomass briquettes and raise
                awareness about renewable energy sources. Through educational
                initiatives, we aim to inform individuals, communities, and
                industries to cleaner energy alternatives.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Benefits;
