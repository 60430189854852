import React, { useEffect } from "react";
import Intro from "../Assets/Intro.mp4";
import Production from "../Assets/Prod.mp4";
import Pellets from "../Assets/Pellets.mp4";
import Footer from "./Footer";

function VideoGallery() {
  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <div>
      <br />
      <div
        className="primary-text"
        style={{
          margin: "auto",
          maxWidth: "1000px",
          padding: "2% 4%",
          fontSize: "1.7rem",
          fontWeight: "bold",
          color: "#087cc4",
        }}
      >
        Video Gallery
      </div>
      <div className="bharat-video-content">
        <div className="video-player">
          <video controls loop muted autoPlay playsInline>
            <source src={Intro} type="video/mp4" />
            Sorry your browser not support video
          </video>
        </div>
        <div className="benefits-heading intro-container">
          <h1>
            Biomass briquettes are compressed, eco-friendly and sustainable
            fuel.
          </h1>
        </div>
      </div>
      <br />
      <br />
      <div className="bharat-video-content-right">
        <div className="video-player">
          <video controls loop muted playsInline>
            <source src={Production} type="video/mp4" />
            Sorry your browser not support video
          </video>
        </div>
        <div className="benefits-heading intro-container">
          <h1>Production of biomass briquettes</h1>
        </div>
      </div>
      <br />
      <br />
      <div className="bharat-video-content">
        <div className="video-player">
          <video controls loop muted playsInline>
            <source src={Pellets} type="video/mp4" />
            Sorry your browser not support video
          </video>
        </div>
        <div className="benefits-heading intro-container">
          <h1>
            Biomass Pellets are compressed, eco-friendly and sustainable fuel.
          </h1>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default VideoGallery;
