import React from "react";
import { Icon } from "@iconify/react";
//import YouTubeIcon from "@mui/icons-material/YouTube";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      <div className="foot" style={{ marginTop: "15%" }}>
        <div className="footer-banner"></div>
        <div className="footer-wrapper">
          <div className="footer-section-two">
            <div className="footer-section-columns">
              <Link to="/journey">
                <span>About Us</span>
              </Link>
              <Link to="/products">
                <span>Products</span>
              </Link>
              <Link to="/partner">
                <span>Parter with us</span>
              </Link>
              <Link to="/contact">
                <span>Contact us</span>
              </Link>
            </div>
            <div className="footer-section-columns">
              <span>+91-9028986499</span>
              <span>exports@bharatbiofuels.com</span>
              <span>info@bharatbiofuels.com</span>
            </div>
            <div className="footer-section-columns">
              {/* <span>Terms & Conditions</span>
              <span>Privacy Policy</span> */}
            </div>
          </div>
          <div className="footer-section-one">
            <div className="footer-icons">
              <Link
                to="https://www.youtube.com/channel/UCP0Bey7B009gOq59kAU3pvA"
                target="_blank"
              >
                <Icon icon="bi:youtube" color="white" width="40" height="40" />
              </Link>
              <Link
                to="https://www.linkedin.com/company/bharat-biofuels/"
                target="_blank"
              >
                <Icon
                  icon="mdi:linkedin"
                  color="white"
                  width="40"
                  height="40"
                />
              </Link>
              {/* <BsYoutube /> */}
              <Link to="https://instagram.com/bharatbiofuels10?igshid=OGQ5ZDc2ODk2ZA==">
                <Icon
                  icon="uil:instagram-alt"
                  color="white"
                  width="40"
                  height="40"
                />
              </Link>
              <Link
                to="https://www.facebook.com/profile.php?id=100095239278644&mibextid=LQQJ4d"
                target="_blank"
              >
                <Icon
                  icon="ion:logo-facebook"
                  color="white"
                  width="40"
                  height="40"
                />
              </Link>
              <Icon
                icon="mingcute:vkontakte-fill"
                color="white"
                width="40"
                height="40"
              />
            </div>
          </div>
        </div>
        <div className="footer-copy">© Copyright 2024 by Bharat Biofuels</div>
      </div>
    </div>
  );
}

export default Footer;
