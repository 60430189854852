import "./App.css";
import React, { useEffect } from "react";
//import Home from "./Components/Home";
import About from "./Components/About";
//import Work from "./Components/Work";
//import Testimonial from "./Components/Testimonial";
import $ from "jquery";
import "jquery-ui/ui/widgets/draggable";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Slider from "./Components/Slider";
import Intro from "./Components/Introduction";
import More from "./Components/About/More";
import Partner from "./Components/Partner";
import Youtube from "./Components/Youtube";
import Hero from "./Components/Hero";
import Products from "./Components/Products";
import Carousel from "./Components/Carousel";
import Home from "./Components/Home";
import MainHome from "./Components/MainHome";
//import ReadMore from "./Components/ReadMore";
import WhyChoose from "./Components/WhyChoose";
import BiomassB from "./Components/BiomassB";
import BiomassP from "./Components/BiomassP";
import GalleryB from "./Components/GalleryB";
import GalleryP from "./Components/GalleryP";
import Benefits from "./Components/Benefits";
import Application from "./Components/Application";
import Goals from "./Components/Goals";
import Journey from "./Components/About/Journey";
import Vision from "./Components/About/Vision";
import Policies from "./Components/Policies";
import VideoGallery from "./Components/VideoGallery";
function App() {
  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 120;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }
  window.addEventListener("scroll", reveal);

  useEffect(() => {
    window.scrollTo(0, 0);
    $(".whatsapp_float").draggable();
  }, []);
  return (
    <Router>
      <div className="App">
        <div id="google_translate_element"></div>
        <Navbar />

        <div className="whatsapp_float">
          <a
            href="https://wa.me/919028986499"
            target="_blank"
            alt="link"
            rel="noreferrer"
          >
            <img
              src="https://i.ibb.co/7QFcnbP/unnamed-1.png"
              width={50}
              alt="unnamed-1"
              border="0"
            />
          </a>
        </div>

        <Routes>
          <Route
            path="/"
            element={
              <>
                <Home />
                <Slider />
                <MainHome />
                <Hero />

                <div className="reveal">
                  <Intro />
                </div>
                {/* <Work /> */}

                <About />
                <WhyChoose />
                <Carousel />

                <Youtube />

                {/* <ReadMore /> */}
                <Footer />
              </>
            }
          />
          {/* Products Starts */}
          <Route path="/biomass-briquettes" element={<BiomassB />} />
          <Route path="/biomass-pellets" element={<BiomassP />} />
          {/* Products Ends */}

          {/* About Starts */}
          <Route path="/journey" element={<Journey />} />
          <Route path="/directors" element={<More />} />
          <Route path="/vision" element={<Vision />} />
          <Route path="/policies" element={<Policies />} />
          <Route path="/video-gallery" element={<VideoGallery />} />
          {/* About Ends */}
          <Route
            path="/briquettes"
            element={<GalleryB heading="Biomass Briquettes" />}
          />
          <Route
            path="/pellets"
            element={<GalleryP heading="Biomass Pellets" />}
          />
          <Route path="/applications" element={<Application />} />

          <Route path="/partner" element={<Partner />} />
          <Route path="/goal" element={<Goals />} />
          <Route path="/products" element={<Products />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/learn-more" element={<More />}></Route>
          <Route path="/benefits" element={<Benefits />}></Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
