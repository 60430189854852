import React, { useEffect, useRef } from "react";
import recycle from "../Assets/climate.jpg";
import backImg from "../Assets/backi.jpg";
import BiomassPRaw from "./BiomassPRaw";
import { useLocation } from "react-router-dom";
import Footer from "./Footer";

function Biomass() {
  const sixmm = useRef(null);
  const eightmm = useRef(null);
  const tenmm = useRef(null);
  const sixteenmm = useRef(null);
  const eighteenmm = useRef(null);
  const thirtymm = useRef(null);
  const location = useLocation();
  useEffect(() => {
    window.scroll(0, 0);
  });
  useEffect(() => {
    if (location.hash === "#scrollsixmm") {
      sixmm.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrolleightmm") {
      eightmm.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrolltenmm") {
      tenmm.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollsixteenmm") {
      sixteenmm.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrolleighteenmm") {
      eighteenmm.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollthirtymm") {
      thirtymm.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [location]);
  return (
    <div>
      <div className="work-section-wrapper">
        <div
          className="work-section-background"
          style={{
            background: `url(${backImg})`,
            backgroundSize: "repeat",
          }}
        >
          <div className="work-section-top">
            <h1 className="primary-mainheading">Biomass Pellets</h1>
          </div>
        </div>
        <div className="metal-image">
          <img src={recycle} alt="Recycling" border="0" />
        </div>
        <br />
        <div className="primary-heading intro-container" ref={sixmm}>
          <h1>6mm Pellets</h1>
        </div>
        <br />
        <div className="biomass-pellets-flex">
          <div className="fruit-images">
            <div className="fruit1">
              <div className="one">
                <img
                  src="https://5.imimg.com/data5/SELLER/Default/2021/1/CF/FO/UM/11850604/6mm-wood-pellet-500x500.jpg"
                  alt=""
                  border="0"
                />
              </div>
              <div className="one">
                <img
                  src="https://www.rotochopper.com/wp-content/uploads/2021/12/wooden-pellets-ecological-heating-2021-10-13-01-47-38-utc-scaled.jpg"
                  alt=""
                  border="0"
                />
              </div>
            </div>
          </div>
          <div className="work-section-top">
            <p>Made with Vertical Ring Die Pellet Machine</p>
            <h3>Applications:</h3>
            <ul>
              <li>Boilers</li>
              <li>Thermal Power Plant</li>
              <li>Burner</li>
              <li>Gasification</li>
            </ul>
          </div>
        </div>

        <br />
        <div className="line-dividing"></div>
        <div className="primary-heading intro-container" ref={eightmm}>
          <h1>8mm Pellets</h1>
        </div>
        <br />
        <div className="biomass-pellets-flex">
          <div className="fruit-images">
            <div className="fruit1">
              <div className="one">
                <img
                  src="https://5.imimg.com/data5/SELLER/Default/2023/2/ZQ/GV/VF/18942184/8mm-biomass-pellet.jpg"
                  alt=""
                  border="0"
                />
              </div>
              <div className="one">
                <img
                  src="https://5.imimg.com/data5/SELLER/Default/2022/3/DW/XS/ZW/35909455/8mm-biomass-briquettes-pellet-500x500.jpg"
                  alt=""
                  border="0"
                />
              </div>
            </div>
          </div>
          <div className="work-section-top">
            <p>Made with Vertical Ring Die Pellet Machine</p>
            <h3>Applications:</h3>
            <ul>
              <li>Boilers</li>
              <li>Thermal Power Plant</li>
              <li>Burner</li>
              <li>Gasification</li>
            </ul>
          </div>
        </div>
        <br />
        <div className="line-dividing"></div>
        <div className="primary-heading intro-container" ref={tenmm}>
          <h1>10mm Pellets</h1>
        </div>
        <br />
        <div className="biomass-pellets-flex">
          <div className="fruit-images">
            <div className="fruit1">
              <div className="one">
                <img
                  src="https://5.imimg.com/data5/SELLER/Default/2022/5/BF/NG/VI/66604770/10-mm-biomass-pellet.jpg"
                  alt=""
                  border="0"
                />
              </div>
              <div className="one">
                <img
                  src="https://5.imimg.com/data5/SELLER/Default/2023/6/317296117/LO/XP/SI/99391027/1686919054402.jpg"
                  alt=""
                  border="0"
                />
              </div>
            </div>
          </div>
          <div className="work-section-top">
            <p>Made with Vertical Ring Die Pellet Machine</p>
            <h3>Applications:</h3>
            <ul>
              <li>Boilers</li>
              <li>Thermal Power Plant</li>
              <li>Burner</li>
              <li>Gasification</li>
            </ul>
          </div>
        </div>
        <br />
        <div className="line-dividing"></div>
        <div className="primary-heading intro-container" ref={sixteenmm}>
          <h1>16mm Pellets</h1>
        </div>
        <br />
        <div className="biomass-pellets-flex">
          <div className="fruit-images">
            <div className="fruit1">
              <div className="one">
                <img
                  src="https://5.imimg.com/data5/SELLER/Default/2023/3/295036645/NQ/NG/FA/133840951/16mm-biomass-wood-pellet.jpeg"
                  alt=""
                  border="0"
                />
              </div>
              <div className="one">
                <img
                  src="https://5.imimg.com/data5/SELLER/Default/2022/12/EJ/GO/UP/157622437/16mm-agriculture-waste-biomass-pellet-500x500.jpg"
                  alt=""
                  border="0"
                />
              </div>
            </div>
          </div>
          <div className="work-section-top">
            <p>Made with Press Die Pellet Machine</p>
            <h3>Applications:</h3>
            <ul>
              <li>Boilers</li>
              <li>Thermal Power Plant</li>
              <li>Burner</li>
              <li>Gasification</li>
            </ul>
          </div>
        </div>
        <br />
        <div className="line-dividing"></div>
        <div className="primary-heading intro-container" ref={eighteenmm}>
          <h1>18mm Pellets</h1>
        </div>
        <br />
        <div className="biomass-pellets-flex">
          <div className="fruit-images">
            <div className="fruit1">
              <div className="one">
                <img
                  src="https://5.imimg.com/data5/SELLER/Default/2023/1/HU/ZZ/WL/181427205/biomass-wood-pellet.jpg"
                  alt=""
                  border="0"
                />
              </div>
              <div className="one">
                <img
                  src="https://5.imimg.com/data5/SELLER/Default/2022/3/BE/EI/XW/81885655/18mm-biomass-wood-pellet.png"
                  alt=""
                  border="0"
                />
              </div>
            </div>
          </div>
          <div className="work-section-top">
            <p>Made with Press Die Pellet Machine</p>
            <h3>Applications:</h3>
            <ul>
              <li>Boilers</li>
              <li>Thermal Power Plant</li>
              <li>Burner</li>
              <li>Gasification</li>
            </ul>
          </div>
        </div>
        <br />
        <div className="line-dividing"></div>
        <div className="primary-heading intro-container" ref={thirtymm}>
          <h1>30mm Pellets</h1>
        </div>
        <br />
        <div className="biomass-pellets-flex">
          <div className="fruit-images">
            <div className="fruit1">
              <div className="one">
                <img
                  src="https://4.imimg.com/data4/KE/HO/ANDROID-13324763/product-500x500.jpeg"
                  alt=""
                  border="0"
                />
              </div>
              <div className="one">
                <img
                  src="https://5.imimg.com/data5/SELLER/Default/2022/5/LI/HH/DJ/151632483/12mm-groundnut-shell-biomass-pellet-500x500.jpeg"
                  alt=""
                  border="0"
                />
              </div>
            </div>
          </div>
          <div className="work-section-top">
            <p>Made with Briquetting Machine</p>
            <h3>Applications:</h3>
            <ul>
              <li>Boilers</li>
              <li>Thermal Power Plant</li>
              <li>Burner</li>
              <li>Gasification</li>
            </ul>
          </div>
        </div>
      </div>
      <BiomassPRaw />
      <Footer />
    </div>
  );
}
export default Biomass;
