import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Briquette from "../Assets/briquette.jpg";
import Pellets from "../Assets/pellets.png";
//import Med from "../Assets/Medicines.jpg";
import { Icon } from "@iconify/react";
function Introduction() {
  const intro = [
    {
      icon: <Icon icon="game-icons:wood-pile" className="intro-icons" />,
      name: "Briquettes",
      roll: 1,
      linkto: "/briquettes",
      link: "/briquettes",
      img: "https://briquettesolution.com/images/honeycomb-burning-effect.jpg",
      backimg: Briquette,
      text: "Browse through our comprehensive range of biomass briquettes, carefully curated to provide solutions for various industries. From specialty compounds to essential briquettes",
    },

    {
      icon: <Icon icon="fa:recycle" className="intro-icons" />,
      name: "Pellets",
      roll: 2,
      linkto: "/pellets",
      link: "/pellets",
      img: "https://i.ibb.co/7WfbL4Z/Glassmorphism-Zero-Waste-Webinar-Instagram-Post-5.png",
      backimg: Pellets,
      text: "Join us in the mission of sustainability by recycling with us. Our dedicated recycling program ensures that materials like biomass pellets are responsibly handled and transformed.",
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <br />
      <br />

      <div className="intro-container">
        <h1 style={{ padding: "21px", width: "fit-content" }}>Our Products</h1>
      </div>
      <div className="intro-section-bottom">
        {intro.map((item) => (
          <Link to={item.linkto}>
            <div
              className="intro-section-info"
              key={item.roll}
              style={{
                background: `linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)) ,url(${item.backimg})`,
                backgroundSize: "100% 100%",

                backgroundRepeat: "no-repeat",
              }}
            >
              <h2>
                {item.icon}
                {item.name}
              </h2>

              {/* <img src={item.img} alt="" /> */}
              <p>{item.text}</p>
              <Link
                className="secondary-button"
                to={item.link}
                style={{ textDecoration: "none", width: "12rem" }}
              >
                Explore More
              </Link>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Introduction;
