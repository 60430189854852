import React from "react";
import "./MoreAbout.css";
import { Icon } from "@iconify/react";
function MoreAbout() {
  return (
    <div>
      <div className="more-container">
        <div className="more-card">
          <div className="more-img">
            <Icon icon="fa-solid:award" color="#fcd40b " className="img" />
          </div>
          <div className="more-content">
            <h4>Uncompromising Quality</h4>
            <p>
              We prioritize quality at every step of the production process.
              From sourcing raw materials to the final packaging, we adhere to
              stringent quality control measures to guarantee consistent and
              reliable briquettes.
            </p>
          </div>
        </div>
        <div className="more-card">
          <div className="more-img">
            <Icon icon="mdi:plant-outline" color="green" className="img" />
          </div>
          <div className="more-content">
            <h4>Sustainable Practices</h4>
            <p>
              We are committed to sustainable manufacturing practices in reduce
              environmental impact. Our focus on utilizing biomass waste as a
              raw material ensures that our briquettes are a carbon-neutral fuel
              option.
            </p>
          </div>
        </div>
        <div className="more-card">
          <div className="more-img">
            <Icon
              icon="ant-design:setting-filled"
              color="#5c5c52"
              className="img"
            />
          </div>
          <div className="more-content">
            <h4>Customized Solutions</h4>
            <p>
              We understand that different industries have unique fuel
              requirements. That's why we offer customized biomass briquettes to
              meet specific energy needs, ensuring optimal performance and
              efficiency.
            </p>
          </div>
        </div>
        <div className="more-card">
          <div className="more-img">
            <Icon icon="gridicons:globe" color="brown" className="img" />
          </div>
          <div className="more-content">
            <h4>Global Reach</h4>
            <p>
              As an exporter, we have successfully established a global
              presence, supplying biomass briquettes to clients around the
              world. Our extensive network and logistics capabilities enable us
              to deliver efficiently.
            </p>
          </div>
        </div>
        <div className="more-card">
          <div className="more-img">
            <Icon icon="mdi:customer-service" color="#087cc4" className="img" />
          </div>
          <div className="more-content">
            <h4>Customer Satisfaction</h4>
            <p>
              We take pride in building strong and lasting relationships with
              our customers. Our dedicated customer support team is always ready
              to assist you, ensuring a seamless experience from inquiry to
              delivery.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MoreAbout;
