import React, { useEffect } from "react";
import "./Gallery.css";
import { Link } from "react-router-dom";
function GalleryP(props) {
  useEffect(() => {
    document
      .querySelectorAll(".image-gallery-container img")
      .forEach((image) => {
        image.onclick = () => {
          document.querySelector(".popup-image").style.display = "block";
          document.querySelector(".popup-image img").src =
            image.getAttribute("src");
        };
      });

    document.querySelector(".popup-image").onclick = () => {
      document.querySelector(".popup-image").style.display = "none";
    };
    window.scroll(0, 0);
  });
  const briquette = [
    {
      img: "https://i.ibb.co/17L5QrZ/6mm.webp",
      heading: "6MM BIOMASS PELLET",
      name: "1",
    },
    {
      img: "https://i.ibb.co/HzCsQp7/8mm.webp",
      heading: "8MM BIOMASS PELLET",
      name: "2",
    },
    {
      img: "https://i.ibb.co/v1FFVzy/10mm.webp",
      heading: "10MM BIOMASS PELLET",
      name: "3",
    },
    {
      img: "https://i.ibb.co/gJ0XKZX/16mm.webp",
      heading: "16MM BIOMASS PELLET",
      name: "4",
    },
    {
      img: "https://i.ibb.co/YXM0fsx/18mm.webp",
      heading: "18MM BIOMASS PELLET",
      name: "5",
    },
    {
      img: "https://i.ibb.co/tHrn48f/30mm.webp",
      heading: "30MM BIOMASS PELLET",
      name: "6",
    },
  ];
  return (
    <div>
      <div className="gallery-container intro-container">
        <h1>{props.heading}</h1>
        <div className="image-gallery-container">
          {briquette.map((item) => (
            <div className="image-gallery" key={item.name}>
              <img src={item.img} alt="" />
              <h2>{item.heading}</h2>
            </div>
          ))}

          <div className="popup-image">
            <span>&times;</span>
            <img
              src="https://5.imimg.com/data5/SELLER/Default/2021/5/XJ/YZ/VI/17087691/mustard-husk-briquettes-500x500.JPG"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="gallery-button">
        <Link to="/biomass-pellets" className="secondary-button">
          {" "}
          Know More
        </Link>
      </div>
      <br />
      <br />
    </div>
  );
}

export default GalleryP;
