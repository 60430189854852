import React, { useEffect } from "react";
import "./Gallery.css";
import { Link } from "react-router-dom";
function GalleryB(props) {
  useEffect(() => {
    document
      .querySelectorAll(".image-gallery-container img")
      .forEach((image) => {
        image.onclick = () => {
          document.querySelector(".popup-image").style.display = "block";
          document.querySelector(".popup-image img").src =
            image.getAttribute("src");
        };
      });

    document.querySelector(".popup-image").onclick = () => {
      document.querySelector(".popup-image").style.display = "none";
    };
    window.scroll(0, 0);
  });
  const briquette = [
    {
      img: "https://i.postimg.cc/V699x6n4/bagasse-briquettes.jpg",
      heading: "BAGASSE",
      name: "1",
    },
    {
      img: "https://5.imimg.com/data5/SELLER/Default/2021/11/TC/HH/DD/5039493/soyabean-biomass-briquettes.jpg",
      heading: "SOYA HUSK",
      name: "2",
    },
    {
      img: "https://5.imimg.com/data5/SELLER/Default/2023/2/PW/TF/AO/3961762/gcv-testing-in-briquettes.png",
      heading: "HIGH GCV AGRIRESEDUE",
      name: "3",
    },
    {
      img: "https://5.imimg.com/data5/IOS/Default/2022/5/OC/PY/VY/150162691/product-jpeg-500x500.png",
      heading: "SAW DUST",
      name: "4",
    },
    {
      img: "https://static.connect2india.com/c2icd/company_resources/3228446/images/products/product-groundnut-shell-briquette.jpg",
      heading: "GROUNDNUT SHELL",
      name: "5",
    },
    {
      img: "https://2.wlimg.com/product_images/bc-full/2021/2/2282675/mustard-husk-biomass-briquettes-1612437832-5713799.jpeg",
      heading: "MUSTARD HUSK",
      name: "6",
    },
  ];
  return (
    <div>
      <div className="gallery-container intro-container">
        <h1>{props.heading}</h1>
        <div className="image-gallery-container">
          {briquette.map((item) => (
            <div className="image-gallery" key={item.name}>
              <img src={item.img} alt="" />
              <h2>{item.heading}</h2>
            </div>
          ))}

          <div className="popup-image">
            <span>&times;</span>
            <img
              src="https://5.imimg.com/data5/SELLER/Default/2021/5/XJ/YZ/VI/17087691/mustard-husk-briquettes-500x500.JPG"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="gallery-button">
        <Link to="/biomass-briquettes" className="secondary-button">
          {" "}
          Know More
        </Link>
      </div>
      <br />
      <br />
    </div>
  );
}

export default GalleryB;
