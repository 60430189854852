import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import shutter from "../Assets/shutter.jpg";
function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="home-container">
      <div className="home-banner-container">
        <div
          className="home-text-section"
          style={{ backgroundImage: `url(${shutter})` }}
        >
          <div className="box">
            <h1>
              Harnessing innovation, protecting our Earth,
              <br />
              Powering progress, preserving nature's worth.
            </h1>
          </div>
          <br />
          <h1 style={{ color: "#fff" }}>Bharat Biofuels</h1>

          <Link
            className="secondary-button"
            to="/journey"
            style={{ textDecoration: "none" }}
          >
            Our Story <FiArrowRight />{" "}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Home;
