import React, { useEffect } from "react";
import MoreAbout from "../MoreAbout";
import Footer from "../Footer";
function Journey() {
  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <div>
      <div className="about-recycling">
        <div
          className="primary-text"
          style={{
            margin: "auto",
            maxWidth: "1000px",
            padding: "4% 4%",
            fontSize: "1.7rem",
            fontWeight: "bold",
            color: "#087cc4",
          }}
        >
          Our Journey - The Bharat Biofuels Story
        </div>
        <div
          className="recycling-text"
          style={{
            paddingTop: "8%",
            margin: "auto",

            textAlign: "left",
            maxWidth: "1000px",
            padding: "0 5%",
          }}
        >
          <p>
            Welcome to Bharat Biofuels a trusted manufacturer and exporter of
            premium-quality biomass briquettes. With a strong commitment to
            sustainability and environmental responsibility, we aim to provide
            innovative fuel solutions that contribute to a greener future. At
            Bharat Biofuels we combine cutting-edge technology, industry
            expertise, and a passion for sustainability to deliver biomass
            briquettes of the highest standards. Our team of dedicated
            professionals works diligently to ensure that our products meet and
            exceed the expectations of our valued customers.
          </p>

          <p>
            With years of experience in the industry, we have perfected our
            manufacturing processes to optimize the conversion of biomass waste
            into efficient and eco-friendly briquettes. By utilizing
            agricultural residues, forest residues, and other biomass waste
            materials, we create a renewable energy source that reduces reliance
            on fossil fuels and helps combat climate change.
          </p>
          <div
            style={{
              margin: "auto",
              maxWidth: "1000px",
              padding: "2% 4%",
              fontSize: "1.7rem",
              fontWeight: "bold",
              color: "#087cc4",
            }}
          >
            Why Choose Bharat Biofuels?
          </div>
          <MoreAbout />
          <br />
          <br />
          <p>
            By choosing Bharat Biofuels, you join the movement towards a
            sustainable and greener future. Our biomass briquettes not only
            offer a reliable and efficient fuel source but also contribute to
            reducing carbon emissions and preserving natural resources. Explore
            our range of biomass briquettes and experience the difference of
            eco-friendly energy solutions. Contact us today to learn more about
            our products, place an order, or discuss your specific requirements.
            Together, let's pave the way for a cleaner and brighter tomorrow.
          </p>
          <div
            style={{
              fontFamily: "Great Vibes",
              margin: "auto",
              fontSize: "2rem",
              textAlign: "center",
            }}
          >
            "Building long-term relationships through exceptional trade
            services!"
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Journey;
