import React, { useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import linkedin from "../../Assets/174857.png";
import Sid from "../../Assets/SiddharthP.jpg";
import Sam from "../../Assets/Saurabh.png";
import Footer from "../Footer";
function Director() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <br />
      <div
        className="primary-text"
        style={{
          margin: "auto",
          maxWidth: "1000px",
          padding: "2% 4%",
          fontSize: "1.7rem",
          fontWeight: "bold",
          color: "#087cc4",
        }}
      >
        Board of Director's
      </div>
      <div className="carousel-director">
        <img src={Sam} alt="" className="director-images" />
        <div className="myCarousel">
          <h3>Mr. Saurabh Pawar</h3>
          <h4>Director</h4>

          <div className="carousel-contact">
            <a href="https://www.linkedin.com/in/saurabh-pawar-827a77169">
              <img src={linkedin} alt=""></img>
            </a>
          </div>
          <p>
            Meet our visionary Director! Armed with a BBA and MBA in Marketing
            and International Business, they've honed their expertise through
            esteemed government courses in international business. With six
            years of invaluable experience in the international market, they are
            passionately committed to sustainability, striving for a greener,
            cleaner future that aligns perfectly with the UN Sustainable
            Development Goals.
          </p>
        </div>
      </div>

      <div className="carousel-director">
        <img src={Sid} alt="IMG-0859" border="0" className="director-images" />
        <div className="myCarousel">
          <h3>Mr. Siddharth Pawar</h3>
          <h4>Director</h4>

          <div className="carousel-contact">
            <a href="https://www.linkedin.com/in/ssiddharthpawar5/">
              <img src={linkedin} alt=""></img>
            </a>
          </div>
          <p>
            Leading tech-preneur having core expertise in waste management,
            sustainability and circular economy. His aim and objective of
            delivering the best of all, he has established strong bonds with our
            suppliers and strategic partners / customers based on trust, good
            business ethics which makes him a reliable player in the global
            market. Join us on this transformative journey towards a brighter
            and enriching tomorrow!
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Director;
