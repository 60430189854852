import React, { useEffect, useRef } from "react";
import recycle from "../Assets/environment.jpg";
import backImg from "../Assets/page-header-bg.jpg";
import { useLocation } from "react-router-dom";
import Footer from "./Footer";
function BiomassB() {
  const Bagasse = useRef(null);
  const soyahusk = useRef(null);
  const highgcv = useRef(null);
  const sawdust = useRef(null);
  const groundnut = useRef(null);
  const mustardhusk = useRef(null);
  const location = useLocation();
  useEffect(() => {
    window.scroll(0, 0);
  });
  useEffect(() => {
    if (location.hash === "#scrollbagasse") {
      Bagasse.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollsoyahusk") {
      soyahusk.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollhighgcv") {
      highgcv.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollsawdust") {
      sawdust.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollgroundnut") {
      groundnut.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollmustardhusk") {
      mustardhusk.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [location]);
  return (
    <div>
      <div className="work-section-wrapper">
        <div
          className="work-section-background"
          style={{
            background: `url(${backImg})`,
            backgroundSize: "100% 100%",
            width: "100%",
          }}
        >
          <div className="work-section-top">
            <h1 className="primary-mainheading">Biomass Briquettes</h1>
          </div>
        </div>

        <div className="metal-image">
          <img src={recycle} alt="Recycling" border="0" />
        </div>
        <br />
        <div
          className="primary-heading intro-container"
          id="scrollbagasse"
          ref={Bagasse}
        >
          <h1>Bagasse Briquettes</h1>
        </div>
        <br />
        <div className="biomass-pellets-flex">
          <div className="fruit-images">
            <div className="fruit1">
              <div className="one">
                <img
                  src="https://i.postimg.cc/T1YbBcRY/1670479569360.png"
                  alt="is-guar-gum-1544222258"
                  border="0"
                />
              </div>
              <div className="one">
                <img
                  src="https://i.postimg.cc/C1wnf4nH/bagasse-briquettes-1620974816-5808822.jpg"
                  alt="guarh"
                  border="0"
                />
              </div>
            </div>
          </div>
          <div className="work-section-top">
            <h3>Specifications:</h3>
            <ul>
              <li>Calorific value is upto 3700 kcal/kg(+/-100)</li>
              <li>Ash Content less than 10%</li>
              <li>Moisture Content Below 12%</li>
            </ul>
          </div>
        </div>
        <br />
        <div className="line-dividing"></div>
        <div
          className="primary-heading intro-container"
          id="scrollsoyahusk"
          ref={soyahusk}
        >
          <h1>Soya Husk Briquettes</h1>
        </div>
        <br />
        <div className="biomass-pellets-flex">
          <div className="fruit-images">
            <div className="fruit1">
              <div className="one">
                <img
                  src="https://5.imimg.com/data5/SELLER/Default/2020/10/RW/AG/IR/22494888/biomass-briquettes.jpg"
                  alt="is-guar-gum-1544222258"
                  border="0"
                />
              </div>
              <div className="one">
                <img
                  src="https://www.agrifarming.in/wp-content/uploads/2019/06/pellets-432096_960_720.jpg"
                  alt="guarh"
                  border="0"
                />
              </div>
            </div>
          </div>
          <div className="work-section-top">
            <h3>Specifications:</h3>
            <ul>
              <li>Calorific value is upto 3500 kcal/kg(+/-100)</li>
              <li>Ash Content less than 10%</li>
              <li>Moisture Content Below 10%</li>
            </ul>
          </div>
        </div>
        <br />
        <div className="line-dividing"></div>
        <div className="primary-heading intro-container" ref={highgcv}>
          <h1>High Gcv Agriresidue Briquettes</h1>
        </div>
        <br />
        <div className="biomass-pellets-flex">
          <div className="fruit-images">
            <div className="fruit1">
              <div className="one">
                <img
                  src="https://5.imimg.com/data5/SELLER/Default/2021/5/PO/VP/RV/12738964/agro-biomass-briquettes.JPG"
                  alt="is-guar-gum-1544222258"
                  border="0"
                />
              </div>
              <div className="one">
                <img
                  src="https://4.imimg.com/data4/RE/VO/MY-15598892/white-coal-500x500-500x500.jpg"
                  alt="guarh"
                  border="0"
                />
              </div>
            </div>
          </div>
          <div className="work-section-top">
            <h3>Specifications:</h3>
            <ul>
              <li>Calorific value is upto 4300 kcal/kg(+/-100)</li>
              <li>Ash Content less than 5%</li>
              <li>Moisture Content Below 5%</li>
            </ul>
          </div>
        </div>
        <br />
        <div className="line-dividing"></div>
        <div className="primary-heading intro-container" ref={sawdust}>
          <h1>Saw Dust Briquettes</h1>
        </div>
        <br />
        <div className="biomass-pellets-flex">
          <div className="fruit-images">
            <div className="fruit1">
              <div className="one">
                <img
                  src="https://4.imimg.com/data4/EU/JD/MY-10669048/sawdust-briquette-500x500.jpg"
                  alt="is-guar-gum-1544222258"
                  border="0"
                />
              </div>
              <div className="one">
                <img
                  src="https://3.imimg.com/data3/HB/NC/MY-6393755/saw-dust-briquette-500x500.jpg"
                  alt="guarh"
                  border="0"
                />
              </div>
            </div>
          </div>
          <div className="work-section-top">
            <h3>Specifications:</h3>
            <ul>
              <li>Calorific value is upto 4000 kcal/kg(+/-100)</li>
              <li>Ash Content less than 5%</li>
              <li>Moisture Content Below 5%</li>
            </ul>
          </div>
        </div>
        <br />
        <div className="line-dividing"></div>
        <div className="primary-heading intro-container" ref={groundnut}>
          <h1>Groundnut Shell Briquettes</h1>
        </div>
        <br />
        <div className="biomass-pellets-flex">
          <div className="fruit-images">
            <div className="fruit1">
              <div className="one">
                <img
                  src="https://5.imimg.com/data5/BC/PP/KX/SELLER-90864809/ground-nut-shell-briquettes.jpg"
                  alt="is-guar-gum-1544222258"
                  border="0"
                />
              </div>
              <div className="one">
                <img
                  src="https://5.imimg.com/data5/VW/EX/AH/SELLER-6524351/groundnut-shell-briquettes-500x500.png"
                  alt="guarh"
                  border="0"
                />
              </div>
            </div>
          </div>
          <div className="work-section-top">
            <h3>Specifications:</h3>
            <ul>
              <li>Calorific value is upto 4000 kcal/kg(+/-100)</li>
              <li>Ash Content less than 5%</li>
              <li>Moisture Content Below 5%</li>
            </ul>
          </div>
        </div>
        <br />
        <div className="line-dividing"></div>
        <div className="primary-heading intro-container" ref={mustardhusk}>
          <h1>Mustard Husk Briquettes</h1>
        </div>
        <br />
        <div className="biomass-pellets-flex">
          {" "}
          <div className="fruit-images">
            <div className="fruit1">
              <div className="one">
                <img
                  src="https://5.imimg.com/data5/SELLER/Default/2021/7/UW/EM/SY/43433723/bri-jpeg-500x500.jpeg"
                  alt="is-guar-gum-1544222258"
                  border="0"
                />
              </div>
              <div className="one">
                <img
                  src="https://5.imimg.com/data5/SELLER/Default/2021/12/GL/ET/YI/45806999/50mm-mustard-husk-biomass-briquettes-500x500.jpg"
                  alt="guarh"
                  border="0"
                />
              </div>
            </div>
          </div>
          <div className="work-section-top">
            <h3>Specifications:</h3>
            <ul>
              <li>Calorific value is upto 3500 kcal/kg(+/-100)</li>
              <li>Ash Content less than 10%</li>
              <li>Moisture Content Below 10%</li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default BiomassB;
