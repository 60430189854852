import React, { useEffect } from "react";
import Footer from "./Footer";
function Partner() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <br />
      <div
        className="primary-text"
        style={{
          margin: "auto",
          maxWidth: "1000px",
          padding: "2% 4%",
          fontSize: "1.7rem",
          fontWeight: "bold",
          color: "#087cc4",
        }}
      >
        Partner with us!
      </div>
      <p
        className="primary-text"
        style={{
          margin: "auto",
          textAlign: "left",
          maxWidth: "1000px",
          fontSize: "1.25rem",
          padding: "2% 2% 0% 2%",
        }}
      >
        We at Bharat Biofuels believe in strong partnerships founded on trust,
        integrity, and mutual benefit. We are continuously looking for partners
        who share our values and commitment to quality as a global supplier of
        high-end and diverse products like biomass briquetts and pellets.
      </p>
      <div
        className="primary-text"
        style={{
          margin: "auto",
          textAlign: "left",
          maxWidth: "1000px",
          fontSize: "1.25rem",
          padding: "3% 2% 4% 2%",
        }}
      >
        We'd love to hear from you if you're one of the manufacturer, supplier,
        or distributor of Biomass products interested in cooperating with us. We
        are continually striving to grow our partner network and connect with
        like-minded companies.
        <br></br>
        Partnering with Bharat Biofuels, you'll gain access to our extensive
        network of clients and our expertise in the Biomass industry. We'll work
        with you to identify opportunities for growth and to develop strategies
        that will help us both achieve our goals.
        <br></br>
        We believe in honesty and fairness in all of our partnerships, and we
        are devoted to developing long-term, mutually beneficial connections. We
        can assist you whether you need to acquire high-quality products, or you
        need a dependable partner to deliver your products.
        <br></br>
        <br></br>
        <div
          className="color-more"
          style={{
            fontSize: "2.5rem",
            fontFamily: "Great Vibes",
            textAlign: "center",
            display: "flex",
            color: "#087cc4",
            justifyContent: "center",
          }}
        >
          Come, let's join hands and grow together!
        </div>
        Get in touch with us today to explore how we can work together and build
        a successful partnership. Let's create a brighter future together.
      </div>

      <Footer />
    </div>
  );
}

export default Partner;
