import React from "react";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
function BiomassPRaw() {
  const pellets = [
    {
      name: 1,
      img: "https://5.imimg.com/data5/SELLER/Default/2022/4/TE/ZI/YC/145938710/groundnut-shell-500x500.jpg",
      head: "Groundnut Shell",
    },
    {
      name: 2,
      img: "https://5.imimg.com/data5/SELLER/Default/2022/6/KD/ND/VS/1753219/soyabean-husk-seed-500x500.jpg",
      head: "Soyabean Husk",
    },
    {
      name: 3,
      img: "https://cdn.britannica.com/31/126031-050-8A05CA97/Bagasse.jpg",
      head: "Bagasse",
    },
    {
      name: 4,
      img: "https://upload.wikimedia.org/wikipedia/commons/5/51/Rice_straw.jpg",
      head: "Straw",
    },
    {
      name: 5,
      img: "https://5.imimg.com/data5/SELLER/Default/2023/3/292706771/BH/PD/QM/25495329/mutard-agro-husk-500x500.png",
      head: "Mustard Husk",
    },
    {
      name: 6,
      img: "https://cottonworks.com/wp-content/uploads/2019/11/GettyImages-627764494.jpg",
      head: "Cotton",
    },
  ];
  const pellets2 = [
    {
      name: 1,
      img: "https://live.staticflickr.com/4279/35038060974_54eea08d41_b.jpg",
      head: "Cotton Stalk",
    },
    {
      name: 2,
      img: "https://5.imimg.com/data5/SELLER/Default/2023/6/318435156/EX/DP/DP/58442972/soya-husk.jpg",
      head: "Soya Husk",
    },
    {
      name: 3,
      img: "https://sc04.alicdn.com/kf/ULB80H14K3QydeJk43PUq6AyQpXan.jpg",
      head: "Cobs",
    },
    {
      name: 4,
      img: "https://4.imimg.com/data4/LT/XO/MY-5308089/paddy-straw.jpg",
      head: "Paddy Straw",
    },
    {
      name: 5,
      img: "https://5.imimg.com/data5/SELLER/Default/2021/1/TR/MF/LC/46888867/agrowaste-cotton-500x500.jpg",
      head: "Agro Waste Straw",
    },
    {
      name: 6,
      img: "https://previews.123rf.com/images/akhmetzyanova/akhmetzyanova1903/akhmetzyanova190300031/120425224-wooden-crumbs-for-landscape-garden-decor.jpg",
      head: "Wood",
    },
  ];
  return (
    <div>
      <br />
      <br />
      <div className="raw-materials intro-container">
        <h1 style={{ width: "auto", padding: "2% 2%" }}>
          Biomass Pellets Raw Materials
        </h1>
      </div>
      <div className="work-section-top">
        <p>
          Know about all the raw materials that we use to make our high-quality
          products.
        </p>
      </div>
      <div className="fruit-container">
        <div className="intro-text">
          <div className="fruit-section-bottom">
            {pellets.map((item) => (
              <div
                className="fruit-section-info"
                key={item.name}
                style={{ backgroundImage: `url(${item.img})` }}
              >
                <h1>{item.head}</h1>
                <p>{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="see-more-content">
        <small>
          <u>slide here</u>
        </small>
        <KeyboardDoubleArrowRightIcon />
      </div>
      <div className="work-section-top">
        <p>
          Familiarize yourself with the assortment of raw materials employed in
          crafting our premium-grade merchandise.
        </p>
      </div>
      <div className="fruit-container">
        <div className="intro-text">
          <div className="fruit-section-bottom">
            {pellets2.map((item) => (
              <div
                className="fruit-section-info"
                key={item.name}
                style={{ backgroundImage: `url(${item.img})` }}
              >
                <h1>{item.head}</h1>
                <p>{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="see-more-content">
        <small>
          <u>slide here</u>
        </small>
        <KeyboardDoubleArrowRightIcon />
      </div>
    </div>
  );
}

export default BiomassPRaw;
