import React from "react";
import Image from "../Assets/WhyChoose.png";
import { Icon } from "@iconify/react";
function WhyChoose() {
  return (
    <div>
      <div className="choose-page">
        <img src={Image} alt="" />
        <div className="choose-right">
          Company's Benefits
          <div className="choose-heading">
            <h1>Why Choose Us</h1>
          </div>
          <div className="choose-content">
            <div className="choose-first">
              <Icon
                icon="teenyicons:tick-circle-solid"
                className="choose-icon"
              />{" "}
              <h3>Superior Quality</h3>
              <br />
              <p>
                We adhere to stringent quality control measures to ensure our
                biomass briquettes meet international standards.
              </p>
            </div>
            <div className="choose-first">
              <Icon
                icon="teenyicons:tick-circle-solid"
                className="choose-icon"
              />{" "}
              <h3>Sustainable Production</h3>
              <br />
              <p>
                Our manufacturing process utilizes biomass waste, such as
                agricultural residues and forest residues, making our briquettes
                an environmentally friendly alternative to traditional fuels.
              </p>
            </div>
            <div className="choose-first">
              <Icon
                icon="teenyicons:tick-circle-solid"
                className="choose-icon"
              />{" "}
              <h3>Global Reach</h3>
              <br />
              <p>
                As an exporter, we serve clients worldwide, delivering our
                biomass briquettes to various industries, including power
                generation, heating, and cooking.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyChoose;
