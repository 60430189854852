import React from "react";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";
import one from "../Assets/1-g.jpg";
import three from "../Assets/3-g.png";
import two from "../Assets/4-g.jpg";
import five from "../Assets/5-g.jpg";
import six from "../Assets/6-g.jpg";
import four from "../Assets/7-g.jpg";
export default function App() {
  return (
    <div>
      <div className="home-page-carousel intro-container">
        <h1 style={{ width: "fit-content", padding: "21px" }}>
          {" "}
          Products Gallery
        </h1>
      </div>
      <MDBCarousel
        className="images-carousel"
        dealy={3000}
        touch={true}
        showControls
      >
        <MDBCarouselItem
          className="w-100 d-block rounded-5"
          itemId={1}
          src={one}
          alt="..."
        ></MDBCarouselItem>
        <MDBCarouselItem
          className="w-100 d-block rounded-5"
          itemId={2}
          src={two}
          alt="..."
        ></MDBCarouselItem>
        <MDBCarouselItem
          className="w-100 d-block rounded-5"
          itemId={3}
          src={three}
          alt="..."
        ></MDBCarouselItem>
        <MDBCarouselItem
          className="w-100 d-block rounded-5"
          itemId={4}
          src={four}
          alt="..."
        ></MDBCarouselItem>
        <MDBCarouselItem
          className="w-100 d-block rounded-5"
          itemId={5}
          src={five}
          alt="..."
        ></MDBCarouselItem>
        <MDBCarouselItem
          className="w-100 d-block rounded-5"
          itemId={6}
          src={six}
          alt="..."
        ></MDBCarouselItem>
      </MDBCarousel>
    </div>
  );
}
