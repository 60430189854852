import React, { useEffect } from "react";
import backImg from "../Assets/recycle.jpg";
import benefits from "../Assets/Application.mp4";
import Faqs from "./Faq";
import Footer from "./Footer";
function Application() {
  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <div>
      <div
        className="work-section-background"
        style={{
          background: `url(${backImg})`,
          backgroundSize: "100% 100%",
          width: "100%",
        }}
      >
        <div className="work-section-top">
          <h1 className="primary-mainheading">
            Applications of Biomass Briquettes
          </h1>
        </div>
      </div>
      <br />
      <div className="bharat-video-content">
        <div className="video-player">
          <video controls loop muted autoPlay playsInline>
            <source src={benefits} type="video/mp4" />
            Sorry your browser not support video
          </video>
        </div>
        <div className="benefits-heading intro-container">
          <h1>
            Progress ignited, nature's essence intact. In harmony we thrive,
            powering a sustainable pact.
          </h1>
        </div>
      </div>
      <div
        className="primary-text"
        style={{
          margin: "auto",
          fontSize: "1.25rem",
          textAlign: "left",
          maxWidth: "1000px",
          padding: "3% 2% 4% 2%",
        }}
      >
        1. <b>Residential Heating</b>: Bharat Biofuels biomass briquettes can be
        used as a source of heat in residential settings. They can be burned in
        stoves, fireplaces, or boilers to provide warmth and comfort for
        households.
        <br />
        <br />
        2. <b>Cooking</b>: Biomass briquettes are an excellent alternative to
        traditional cooking fuels like wood and charcoal. They can be used in
        cooking stoves, grills, and ovens, providing a sustainable and efficient
        fuel source for cooking purposes.
        <br />
        <br />
        3. <b>Industrial Heating</b>: Many industries require heat for their
        processes, such as drying, curing, and heating. Bharat Biofuels biomass
        briquettes can be used in industrial furnaces, kilns, and boilers to
        generate heat and provide an eco-friendly solution for industrial
        heating needs.
        <br />
        <br />
        4. <b>Power Generation</b>: Biomass briquettes can be used as feedstock
        in thermal power plants to generate electricity. The briquettes are
        burned in boilers to produce steam, which drives turbines and generates
        electricity, contributing to renewable and sustainable power generation.
        <br />
        <br />
        5. <b>Commercial Applications</b>: Bharat Biofuels biomass briquettes
        find applications in commercial settings such as hotels, restaurants,
        and cafeterias. They can be used for cooking, heating water, and
        providing heat in various commercial processes.
        <br></br>
        <br></br>
        6. <b>Export</b>: Bharat Biofuels biomass briquettes can be exported to
        countries where there is a demand for sustainable energy sources.
        Biomass briquettes offer a renewable and carbon-neutral fuel
        alternative, making them an attractive option for international markets.
        <br></br>
        <br></br>
      </div>
      <h2>Some of the Frequently asked questions!</h2>
      <br />

      <Faqs />
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default Application;
