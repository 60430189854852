import React from "react";
import cycle from "../Assets/netzero.gif";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
function MainHome() {
  return (
    <div className="home-page-main">
      <div className="home-page">
        <div className="home-page-heading">
          POWERING PROGRESS,{" "}
          <span style={{ color: "#087cc4" }}>PRESERVING NATURE</span>{" "}
        </div>
        <div className="home-page-text">
          {" "}
          Paving green paths, balancing progress, Harmonizing nature,
          sustainable endeavors impress.
        </div>
        <br />
        <div className="home-page-button">
          <Link to="/applications" className="home-button sol">
            Learn More
          </Link>
          <Link to="/contact" className="home-button tr">
            Let's Connect
          </Link>
        </div>
      </div>
      <div className="home-page-1">
        <Fade>
          <img src={`${cycle}`} alt="" className="img-fluid" />
        </Fade>
      </div>
    </div>
  );
}

export default MainHome;
