import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import HeroImage from "../Assets/MainHome.jpg";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Typed from "react-typed";
import "../App.css";
const Hero = () => {
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  return (
    <Grid container spacing={4} style={{ color: "#3e3d3d" }}>
      <Grid item container xs={12} md={6} alignItems={"center"}>
        <Box
          data-aos={isMd ? "fade-right" : "fade-up"}
          paddingLeft={isMd && 2}
          p={1}
          style={{ background: "#fff" }}
        >
          <Box marginBottom={2}>
            <Typography
              variant="h4"
              color="text.primary"
              sx={{
                fontWeight: 700,
                display: "grid",
                color: "#3e3d3d",
                margin: "auto",
                fontFamily: "Raleway",
              }}
            >
              Explore our wide range of products! <br />
              You Might Like{" "}
              <Typography
                color="#ed3d24"
                component={"span"}
                variant={"inherit"}
              >
                <Typed
                  strings={[
                    "Biomass Briquettes",
                    "Biomass Pellets",
                    "Bagasse",
                    "Corn cobs",
                    "Soyabean",
                    "Mustard Husk",
                  ]}
                  typeSpeed={100}
                  loop={true}
                />
              </Typography>
            </Typography>
          </Box>
          <Box marginBottom={3}>
            <Typography
              variant="h6"
              component="p"
              color="#3e3d3d"
              style={{
                fontFamily: "Raleway",
                fontWeight: "600",
              }}
            >
              Welcome to{" "}
              <span
                style={{
                  color: "#087cc4",
                  fontWeight: "800",
                  fontFamily: "sans-serif",
                }}
              >
                Bharat Biofuels{" "}
              </span>
              your trusted source for high-quality biomass briquettes. We
              specialize in manufacturing and exporting eco-friendly fuel
              solutions that help reduce carbon emissions and promote
              sustainable energy practices.
              <br />
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        container
        alignItems={"center"}
        justifyContent={"center"}
        xs={12}
        md={6}
        data-aos="flip-left"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="2000"
      >
        <Box
          component={LazyLoadImage}
          height={1}
          width={1}
          src={HeroImage}
          alt="..."
          effect="blur"
          boxShadow={3}
          borderRadius={2}
          maxWidth={600}
          sx={{
            filter: theme.palette.mode === "dark" ? "brightness(0.7)" : "none",
            padding: "10px",
            boxShadow: "0px 0px 12px 1px #087cc4",
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Hero;
