import React from "react";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";

export default function App() {
  return (
    <MDBContainer
      className="mt-5"
      style={{ maxWidth: "1000px", background: "transparent" }}
    >
      <MDBAccordion alwaysOpen initialActive={1}>
        <MDBAccordionItem
          collapseId={1}
          headerTitle="1. What are biomass briquettes?"
        >
          Biomass briquettes are compact blocks or pellets made from organic
          waste materials such as agricultural residues, forestry residues, and
          biodegradable organic materials. They are a sustainable and renewable
          energy source.
        </MDBAccordionItem>
        <MDBAccordionItem
          collapseId={2}
          headerTitle="2. How are Bharat Biofuels biomass briquettes made? "
        >
          Bharat Biofuels biomass briquettes are made through a process that
          involves crushing, drying, and compressing the biomass feedstock. The
          biomass is compacted under high pressure without the use of any
          binders or chemicals, resulting in dense and uniform briquettes.
        </MDBAccordionItem>
        <MDBAccordionItem
          collapseId={3}
          headerTitle="3. What is the calorific value of Bharat Biofuels biomass briquettes? "
        >
          The calorific value of biomass briquettes varies depending on the
          composition and moisture content of the biomass feedstock. However,
          Bharat Biofuels ensures that their biomass briquettes have a high
          calorific value, providing efficient and effective heat energy.
        </MDBAccordionItem>
        <MDBAccordionItem
          collapseId={4}
          headerTitle="4. How do biomass briquettes compare to traditional fuels?"
        >
          Biomass briquettes have several advantages over traditional fuels like
          coal, wood, and charcoal. They are renewable, carbon-neutral, and
          produce less smoke and harmful emissions. Biomass briquettes also have
          a higher energy content and are more compact, making them easier to
          store and transport.
        </MDBAccordionItem>
        <MDBAccordionItem
          collapseId={5}
          headerTitle="5. What are the environmental benefits of using Bharat Biofuels biomass briquettes? "
        >
          Using biomass briquettes helps in reducing greenhouse gas emissions
          and air pollution. By utilizing organic waste materials, biomass
          briquettes contribute to waste management and help in preventing the
          burning or decomposition of biomass residues, thereby reducing
          environmental impact.
        </MDBAccordionItem>
        <MDBAccordionItem
          collapseId={6}
          headerTitle="6. Can Bharat Biofuels biomass briquettes be used in existing stoves and boilers? "
        >
          Yes, Bharat Biofuels biomass briquettes are designed to be compatible
          with existing stoves, boilers, and other combustion devices. They can
          be used as a direct replacement for traditional fuels without
          requiring any modifications to the equipment.
        </MDBAccordionItem>
        <MDBAccordionItem
          collapseId={7}
          headerTitle="7. Where can I buy Bharat Biofuels biomass briquettes? "
        >
          Bharat Biofuels biomass briquettes can be purchased directly from
          their website by contacting us.
        </MDBAccordionItem>
        <MDBAccordionItem
          collapseId={8}
          headerTitle="8. Are Bharat Biofuels biomass briquettes cost-effective? "
        >
          Yes, biomass briquettes can provide cost advantages over traditional
          fuels, especially in regions where biomass feedstock is abundant. The
          cost of biomass briquettes may vary depending on factors such as
          location and quantity, but they offer an affordable and sustainable
          energy alternative.
        </MDBAccordionItem>
        <MDBAccordionItem
          collapseId={9}
          headerTitle="9. Are Bharat Biofuels biomass briquettes suitable for large-scale industrial applications? "
        >
          Yes, Bharat Biofuels biomass briquettes are suitable for large-scale
          industrial applications. They can be used in industrial furnaces,
          boilers, and power plants, providing heat energy and contributing to
          renewable power generation.
        </MDBAccordionItem>
        <MDBAccordionItem
          collapseId={10}
          headerTitle="10. How does using Bharat Biofuels biomass briquettes contribute to sustainable development? "
        >
          By using biomass briquettes, you contribute to sustainable development
          by reducing dependence on fossil fuels, promoting waste management,
          and supporting a cleaner and greener energy system. Biomass briquettes
          help in mitigating climate change, conserve natural resources, and
          create a more sustainable future.
        </MDBAccordionItem>
      </MDBAccordion>
    </MDBContainer>
  );
}
